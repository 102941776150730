import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { COLOR } from '../globals'

const HeaderButtonStyled = styled(Button)`
  border: none !important;
  box-shadow: none !important;
  font-size: 1.2em !important;
  background: none !important;
  color: ${({ isactive }) => (isactive ? COLOR : 'rgba(0, 0, 0, 0.5)')} !important;
  padding: 0.1em !important;
  @media (min-width: 769px) {
    font-size: 1.1em !important;
    background: ${({ isactive }) => (isactive ? COLOR : '#e0e1e2 none')} !important;
    color: ${({ isactive }) => (isactive ? '#ffffff' : COLOR)} !important;
    padding: 0.5em !important;
  }
`
const HeaderButton = ({ isActive, ...restProps }) => {
  return <HeaderButtonStyled circular isactive={isActive ? 1 : 0} {...restProps} />
}

HeaderButton.propTypes = {
  isActive: PropTypes.bool,
}

HeaderButton.defaultProps = {
  isActive: false,
}

export default HeaderButton
