import { useContext, useRef, useState, useEffect } from 'react'

import { TokenContext } from '../components/Context'
import myAxios from '../axios'

const FETCHSTATES = {
  loading: 'loading',
  error: 'error',
  success: 'success',
}

export const useFetch = (endpoint, vars = '', type = 'GET', parameters = {}, baseURL = null) => {
  const source = myAxios.CancelToken.source()

  const { token } = useContext(TokenContext)

  const [status, setStatus] = useState(FETCHSTATES.loading)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const memoizedParameters = useRef(parameters)

  useEffect(() => {
    setData(null)
  }, [endpoint])

  useEffect(() => {
    setStatus(FETCHSTATES.loading)
    myAxios({
      method: type,
      url: `${endpoint}${vars}`,
      cancelToken: source.token,
      ...(baseURL ? { baseURL } : {}),
      ...(token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}),
      ...(memoizedParameters.current ? { data: memoizedParameters.current } : {}),
    })
      .then(({ data }) => {
        setData(data)
        setError(null)
        setStatus(FETCHSTATES.success)
      })
      .catch((error) => {
        if (!myAxios.isCancel(error)) {
          setData(null)
          setError(error)
          setStatus(FETCHSTATES.error)
        }
        console.error(error)
      })

    return () => source.cancel('Petición cancelada')
    // eslint-disable-next-line
  }, [endpoint, vars, type, token])

  return [data, status, error]
}
