import React from 'react'

import CloudFilesExplorer from '../CloudFilesExplorer'

const DocumentsWidget = () => {
  return (
    <div style={{ height: '300px' }}>
      <CloudFilesExplorer baseFolder="DocumentosClientes/" />
    </div>
  )
}

export default DocumentsWidget
