import React from 'react'
import * as Sentry from '@sentry/browser'

import InfoMessage from './InfoMessage'

class LazyLoadErrorBoundary extends React.Component {
  state = { hasLazyLoadError: false }

  static getDerivedStateFromError(error) {
    return { hasLazyLoadError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
    this.reloadPage()
  }

  reloadPage = () => {
    if (localStorage.getItem('cenfarte.pageReloaded') === '1') {
      localStorage.removeItem('cenfarte.pageReloaded')
    } else {
      localStorage.setItem('cenfarte.pageReloaded', '1')
      window.location.reload(true)
    }
  }

  render() {
    if (this.state.hasLazyLoadError)
      return (
        <InfoMessage
          icon="warning"
          title="ERROR"
          description={
            <>
              <div>Se ha producido un error al intentar cargar la sección.</div>
              <div>
                Recargue la página para intentarlo de nuevo y si el problema persiste, contacte con
                el departamento técnico.
              </div>
            </>
          }
        />
      )

    return this.props.children
  }
}

export default LazyLoadErrorBoundary
