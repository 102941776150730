import Dexie from 'dexie'

const indexedDb = new Dexie(
  process.env.REACT_APP_ENVIRONMENT === 'production' ? 'cenfarteDb' : 'cenfarteDevelopmentDb'
)
indexedDb.version(2).stores({
  pharmacies: 'id',
  products: 'id',
  offers: '++id',
  transfers: '++id',
  carts: '++id, userUsername, [userUsername+productSapCode]',
})
indexedDb.version(1).stores({
  pharmacies: 'id',
  products: 'id',
  offers: '++id',
  transfers: '++id',
  carts: '++id, userKunnr, [userKunnr+productSapCode]',
})

export default indexedDb
