import React, { useContext } from 'react'

import { ProductsContext } from './Context'
import HeaderButton from './HeaderButton'

/**
 * Component: HeaderDisplayPrices
 * Header cart icon that links to current cart.
 */
const HeaderDisplayPrices = React.memo(({ ...restProps }) => {
  const { displayPrices, setDisplayPricesStatus } = useContext(ProductsContext)

  const handleChangeDisplayPrices = () => {
    setDisplayPricesStatus(!displayPrices)
  }

  return (
    <HeaderButton
      icon="euro"
      isActive={displayPrices}
      onClick={handleChangeDisplayPrices}
      {...restProps}
    />
  )
})

export default HeaderDisplayPrices
