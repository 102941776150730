import React from 'react'
import { es } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import styled from 'styled-components'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useAdminFetch } from '../../hooks'
import { COLOR } from '../../globals'

const EventsWidgetContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
  & .events__empty {
    text-align: center;
    font-style: italic;
    margin: 2rem;
  }
  & .events__event {
    display: block;
    color: #000;
    padding: 0.8rem 1.4rem;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
    a {
      color: ${COLOR};
    }
  }
  & .events__event__date {
    font-size: 0.9em;
  }
  & .events__event__title {
    font-size: 1.1em;
    font-weight: bold;
  }
  & .events__event__location {
    font-size: 1em;
    line-height: 1.2;
    color: #666;
  }
  & .events__event__description {
    font-size: 0.9em;
    margin-top: 0.4rem;
    font-style: italic;
  }
`

const EventsWidget = () => {
  const [data, status] = useAdminFetch('/social_events/public')

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error')
    return <WidgetError description="No se pueden cargar los últimos eventos" />

  const events = data.data || []

  return (
    <EventsWidgetContainer>
      {events.length > 0 ? (
        events.map(({ title, location, map_link, date, description }) => {
          return (
            <div key={title} className="events__event">
              <div className="events__event__date">
                {format(parseISO(date), "dd' de 'MMMM' de 'yyyy", {
                  locale: es,
                })}
              </div>
              <div className="events__event__title">{title}</div>
              <div className="events__event__location">
                {location}
                {map_link && (
                  <>
                    {' '}
                    [
                    <a href={map_link} target="_blank" rel="noopener noreferrer">
                      ver mapa
                    </a>
                    ]
                  </>
                )}
              </div>
              {description && <div className="events__event__description">{description}</div>}
            </div>
          )
        })
      ) : (
        <div className="events__empty">No hay eventos</div>
      )}
    </EventsWidgetContainer>
  )
}

export default EventsWidget
