import React, { useContext, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Segment, Image, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserContext } from '../components/Context/User'
import LoginForm from '../components/LoginForm'

import { APIBASEURL, APIREQUESTOPTIONS, COLOR } from '../globals'

import logo from '../images/logo-cenfarte.svg'

/**
 * Component: Highlight
 * Displays a piece of higlighted information.
 */
const Highlight = React.memo(({ icon, title, description, ...rest }) => (
  <div {...rest}>
    <div>
      <Icon
        circular
        name={icon}
        style={{ color: COLOR, fontSize: '1.8em', marginBottom: '0.6em' }}
      />
    </div>
    <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '0.6em' }}>{title}</div>
    <div style={{ fontSize: '1.2em', color: '#888' }}>{description}</div>
  </div>
))

const Logo = styled(Image)`
  width: 180px;
  margin-bottom: 3rem;
  @media (min-width: 1024px) {
    width: 210px;
  }
`
const Main = styled.main`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  @media (min-height: 500px) {
    justify-content: center;
  }
`
const CentralBanner = styled.div`
  margin: 7rem 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    background: rgba(255, 255, 255, 0.9);
  }
`
const Information = styled.div`
  display: none;
  font-size: 0.85rem;
  margin: 3em 3em 3em 0;
  & .information__title {
    font-size: 2.4em;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 0.6rem;
  }
  & .information__subtitle {
    font-size: 1.2em;
    color: #888;
    margin-bottom: 2em;
  }
  @media (min-width: 1024px) {
    display: block;
    width: 500px;
  }
  @media (min-width: 1280px) {
    width: 680px;
    font-size: 1rem;
  }
`
const Highlights = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -1rem;
  & > div {
    flex: 1 1 auto;
    margin: 0 1rem;
  }
`
const LoginContainer = styled.div`
  margin: -5rem 0;
`
const StyledLoginForm = styled(Segment)`
  padding: 2.4rem !important;
  width: 330px;
  @media (min-width: 1024px) {
    width: 360px;
  }
`

/**
 * Component: ScreensLogin
 * Login screen.
 */
const ScreensLogin = ({ pwdResetToken }) => {
  const user = useContext(UserContext)

  // Set focus on login input when component is mounted.
  useEffect(() => {
    const loginInput = document.getElementsByName('login')[0]
    if (loginInput) loginInput.focus()
  }, [])

  /* HANDLERS */

  // When receives login response, check if has been successful.
  const handleLoginResponseValidation = response => {
    return response.data.login === true
  }

  // When login has been successful.
  const handleLoginSuccess = ({ data }) => {
    user.setUserInfo({
      ...data.user,
      impersonated: false,
      impersonatorId: data.user.id,
      impersonatorRole: data.user.role,
    })
  }

  // When receives password reminder response, check if has been successful.
  const handlePasswordReminderResponseValidation = response => {
    return response.data.reset && response.data.errors.length === 0
  }

  // When receives password reset response, check if has been successful.
  const handlePasswordResetResponseValidation = response => {
    return (
      response.data.saved &&
      (!response.data.errors || Object.keys(response.data.errors).length === 0)
    )
  }

  // When password reset process has ended and press the enter button.
  const handlePasswordResetEnd = () => {
    navigate('/')
    window.location.reload(true)
  }

  return (
    <Main>
      <CentralBanner>
        <Information>
          <div className="information__title">Ahora todo es más fácil</div>
          <div className="information__subtitle">
            Hemos pulido con esmero cada detalle de tu nuevo área de cliente
          </div>
          <Highlights>
            <Highlight
              icon="search"
              title="Un buscador eficaz"
              description="Busca mientras tecleas entre las más de 25.000 referencias en catálogo, así como en tus facturas y albaranes"
            />
            <Highlight
              icon="shopping bag"
              title="Compra más fácil"
              description="Hacer un pedido a Cenfarte es ahora mucho más fácil gracias a un proceso de compra simplificado"
            />
            <Highlight
              icon="rocket"
              title="Más rápido que nunca"
              description="Hemos pensado cada detalle para que tus operaciones online con Cenfarte sean más rápidas que nunca"
            />
          </Highlights>
        </Information>
        <LoginContainer>
          <StyledLoginForm>
            <Logo centered src={logo} />
            <LoginForm
              urlLogin={`${APIBASEURL}/login`}
              apiRequestOptions={APIREQUESTOPTIONS}
              loginUrlVar="username"
              pwdUrlVar="password"
              errorMessageLogin={
                'Mmmm... Algo raro ha sucedido. Será mejor que lo vuelvas a intentar.'
              }
              loginResponseValidation={handleLoginResponseValidation}
              handleLoginSuccess={handleLoginSuccess}
              urlLoginReminder={`${APIBASEURL}/recovery`}
              loginReminderResponseValidation={handlePasswordReminderResponseValidation}
              {...(pwdResetToken ? { passwordResetToken: pwdResetToken } : {})}
              passwordResetUrl={`${APIBASEURL}/reset`}
              passwordResetResponseValidation={handlePasswordResetResponseValidation}
              onPasswordResetEnd={handlePasswordResetEnd}
              buttonProps={{
                style: {
                  color: '#fff',
                  background: COLOR,
                  padding: '1.4rem 1rem',
                },
              }}
            />
          </StyledLoginForm>
        </LoginContainer>
      </CentralBanner>
    </Main>
  )
}

export default ScreensLogin
