import 'date-input-polyfill-react'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/array/some'
import 'core-js/features/object/values'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import * as Sentry from '@sentry/browser'
import { CookiesProvider } from 'react-cookie'

import { UserProvider, TokenProvider, ApiProvider, MessagesProvider } from './components/Context'
import InternetExplorerWarning from './screens/InternetExplorerWarning'
import Root from './screens/Root'

import { detectIE } from './utils'

import 'semantic-ui-css/semantic.min.css'
import { WarehouseProvider } from './components/Context/Warehouse'

Sentry.init({
  dsn: 'https://28a5e08576ad431f9746ede096ae0106@bugs.zitelia.com/1',
  environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'development',
})

ReactDOM.render(
  detectIE() ? (
    <InternetExplorerWarning />
  ) : (
    <CookiesProvider>
      <UserProvider>
        <TokenProvider>
          <ApiProvider>
            <WarehouseProvider>
            <MessagesProvider>
              <Root />
            </MessagesProvider>
            </WarehouseProvider>
          </ApiProvider>
        </TokenProvider>
      </UserProvider>
    </CookiesProvider>
  ),
  document.getElementById('root')
)
