import { createGlobalStyle } from 'styled-components'

import { COLOR } from '../../globals'

import bkgLogin from '../../images/bkg-login.jpg'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    font-size: 14px;
    margin: 0;
    background-color: ${({ login }) => (login ? 'transparent' : '#fbfbfb')};
    overscroll-behavior-x: none;
  }
  body {
    background: ${({ login }) =>
      `${login ? `url(${bkgLogin}) center no-repeat` : '#fbfbfb'} !important`};
    background-size: cover !important;
  }
  h1 {
    font-weight: bold;
    margin: 0;
  }
  a,
  a:hover {
    color: #000;
  }
  button,
  a:hover {
    cursor: pointer;
  }
  a:hover button {
    opacity: 0.85;
  }
  #root {
    height: 100%;
    display: ${({ login }) => (login ? 'default' : 'flex')};
    flex-flow: column nowrap;
  }
  main {
    flex: ${({ login }) => (login ? 'default' : '1 1 auto')};
    margin: ${({ login }) => (login ? 'default' : '1rem 0 4rem 0;')};
  }
  .input--secret {
    -webkit-text-security: disc !important;
    -moz-text-security: disc !important;
  }
  .button--green {
    background: ${COLOR} !important;
    color: #fff !important;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 1;
    }
  }
  @media (min-width: 769px) {
    html, body {
      font-size: 13px !important;
    }
  }
`
