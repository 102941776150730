import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

const InputRangeStyled = styled.div`
  & .range-limits {
    display: flex;
    font-size: 0.9em;
    padding-left: 0.6rem;
    margin-top: -0.6rem;
  }
  & .range-limits__min {
    flex: 0 1 auto;
  }
  & .range-limits__max {
    flex: 1 1 auto;
    text-align: right;
  }
`

export const InputRange = React.memo(
  ({
    label,
    min,
    max,
    value,
    onChange,
    formatValues,
    className,
    ...restProps
  }) => {
    const handleValueChange = e => {
      onChange(e.target.value)
    }

    return (
      <InputRangeStyled {...(className ? { className } : {})}>
        <div>
          {label}: <strong>{formatValues(value)}</strong>
        </div>
        <Input
          fluid
          type="range"
          {...(min ? { min } : {})}
          {...(max ? { max } : {})}
          value={value}
          onChange={handleValueChange}
          {...restProps}
        />
        <div className="range-limits">
          {min !== undefined && (
            <div className="range-limits__min">{formatValues(min)}</div>
          )}
          {max !== undefined && (
            <div className="range-limits__max">{formatValues(max)}</div>
          )}
        </div>
      </InputRangeStyled>
    )
  }
)

InputRange.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  formatValues: PropTypes.func,
  className: PropTypes.string,
}

InputRange.defaultProps = {
  label: 'Valor',
  formatValues: value => value,
}
