import React, { PureComponent, createContext } from 'react'

import { withUserContext } from './User'
import { withTokenContext } from './Token'

import myAxios from '../../axios'

export const ApiContext = createContext()

class ApiProviderWithoutUserContext extends PureComponent {
  state = {
    apiRequest: (type, endpoint, data = {}, baseURL = null) => {
      const { token } = this.props.token

      return new Promise((resolve, reject) => {
        myAxios({
          method: type,
          url: endpoint,
          ...(baseURL ? { baseURL } : {}),
          ...(token
            ? {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            : {}),
          data,
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.error(error)
            //this.props.user.logoutUser()
            if (error.response && error.response.data && error.response.data.code === 401)
              this.props.user.logoutUser()
            reject(error)
          })
      })
    },
    getRequest: (endpoint, baseURL = null) => this.state.apiRequest('get', endpoint, {}, baseURL),
    postRequest: (endpoint, data, baseURL = null) =>
      this.state.apiRequest('post', endpoint, data, baseURL),
    putRequest: (endpoint, data, baseURL = null) =>
      this.state.apiRequest('put', endpoint, data, baseURL),
    deleteRequest: (endpoint, baseURL = null) =>
      this.state.apiRequest('delete', endpoint, {}, baseURL),
  }

  render() {
    return <ApiContext.Provider value={this.state}>{this.props.children}</ApiContext.Provider>
  }
}

export const ApiProvider = withUserContext(withTokenContext(ApiProviderWithoutUserContext))

export function withApiContext(Component) {
  return function WithApiContextComponent(props) {
    return <ApiContext.Consumer>{(api) => <Component {...props} api={api} />}</ApiContext.Consumer>
  }
}
