import React from 'react'
import { Link } from '@reach/router'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { useCookies } from 'react-cookie'

import { COLOR } from '../globals'

const CookiesPolicyBannerContainer = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  color: #dddddd;
  font-size: 0.9em;
  padding: 1.4em;
  text-align: center;
  a,
  a:hover,
  a:visited,
  a:active {
    color: ${COLOR};
    font-weight: bold;
  }
  .cookies-policy__text {
    margin-bottom: 1rem;
  }
  @media (min-width: 769px) {
    font-size: 1.05em;
    padding: 2em 5%;
  }
`

const CookiesPolicyBanner = () => {
  const [, setCookie] = useCookies(['acceptcookies'])

  const handleAccept = () => {
    setCookie('acceptcookies', 'yes', { expires: new Date(2030, 1, 1) })
  }

  return (
    <CookiesPolicyBannerContainer>
      <div className="cookies-policy__text">
        Este sitio web utiliza cookies para mejorar la experiencia de usuario. Si continúa
        navegando, está dando su consentimiento para la aceptación de las cookies y de nuestra{' '}
        <Link to="/cookies">Política de Cookies</Link>. Pinche el enlace para mayor información.
      </div>
      <Button content="Aceptar" className="cookies-policy__button" onClick={handleAccept} />
    </CookiesPolicyBannerContainer>
  )
}

export default CookiesPolicyBanner
