import React, { PureComponent, createContext } from 'react'
import axios from 'axios'

import { APIADMINBASEURL } from '../../globals'
import {format, differenceInSeconds, startOfDay } from 'date-fns'


export const WarehouseContext = createContext()

export class WarehouseProvider extends PureComponent {
  state = {
    userStatus: 'checking',
    delivery: [],
    warehouses: [],
    holidays: [],
    setWarehouseInfo: ({
      delivery,
      warehouses,
      holidays,
    }) => {
      this.setState({
        userStatus: 'ok',
        delivery: delivery,
        warehouses: warehouses,
        holidays: holidays,
      })
    },
    getHolidays: ()=>{
      return this.state.holidays
    },
    getDelivery: ()=>{
      return this.state.delivery
    },
    getUserWarehouse: (userRoute) => {
      let userFirstChar = "";
      if(userRoute) userFirstChar = userRoute[0];
      return userRoute ? this.state.warehouses.find(x => x?.code.charAt(0) === userFirstChar) : this.state.warehouses.find(x => x?.code === "1000")
    },
    getWarehouseByLiteral: (warehouseLiteral) => {
      return this.state.warehouses.find(x => x?.literal === warehouseLiteral)
    },
    getWarehousePriorityInterval: (userRoute) => {
      if(this.userStatus === 'checking' || !this.state.warehouses) return null;
      if(this.state.warehouses.length <= 0) return null;
      let userFirstChar = "";
      if(userRoute) userFirstChar = userRoute[0];
      let ware = userRoute ? this.state.warehouses.find(x => x?.code.charAt(0) === userFirstChar) : this.state.warehouses.find(x => x?.code === "1000")
      let warehouseCode = ware.literal;

      const HOLIDAYS = this.state.holidays
      const DELIVERYDAYS = this.state.delivery
  
      const startOfToday = startOfDay(new Date())

      /**
       * @description Check if current day is in the holidays list or not.
       */
      const isHoliday = [...HOLIDAYS.all, ...HOLIDAYS[warehouseCode]].includes(
        format(startOfToday, 'yyyyMMdd')
      )

      /**
       * @description Name of the current day of the week.
       * @var {String}
       * @example 'thursday'
       */
      const todayWeekday = new Date().toLocaleString('en', { weekday: 'long' }).toLowerCase()

      /**
       * @description Difference in seconds from the beginning of the day until
       * now. Useful to calculate the time interval to be taken as a reference.
       * @var {Number}
       */
      const secondsOfToday = differenceInSeconds(new Date(), startOfToday)

      /**
       * @var {Object}
       * @example
       * {
       *   until: 86399, // 23:59:59
       *   warehouse_priority: ['sa', 'as', 'vi', 'pa', 'ma'],
       *   delivery_days: { sa: 1, vi: 2, ma: 2, pa: 1, as: 2 },
       * }
       */
      const timeInterval = (
        isHoliday && DELIVERYDAYS[warehouseCode].hasOwnProperty('holiday')
          ? DELIVERYDAYS[warehouseCode]['holiday']
          : DELIVERYDAYS[warehouseCode].hasOwnProperty(todayWeekday)
          ? DELIVERYDAYS[warehouseCode][todayWeekday]
          : DELIVERYDAYS[warehouseCode]['default']
      )
        .filter((timerange) => timerange.until > secondsOfToday)
        .reduce((reduced, timerange) =>
          timerange.until - secondsOfToday < reduced - secondsOfToday ? timerange.until : reduced
        )

        return timeInterval.warehouse_priority;
    },
    checkWarehouseStatus: () => {
      axios({
        method: 'GET',
        url: `${APIADMINBASEURL}/warehouses-schedules/public`,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(({ data }) => {
          if (data.success) {
            this.state.setWarehouseInfo({
              delivery: data.data.delivery,
              warehouses: data.data.warehouses,
              holidays: data.data.holidays
            })
          } else {

          }
        })
        .catch(error => {
          console.error(error)
        })
    },
  }



  render() {
    return <WarehouseContext.Provider value={this.state}>{this.props.children}</WarehouseContext.Provider>
  }
}

export const WarehouseConsumer = WarehouseContext.Consumer

export function withWarehouseContext(Component) {
  return function WithWarehouseContextComponent(props) {
    return (
      <WarehouseContext.Consumer>{user => <Component {...props} user={user} />}</WarehouseContext.Consumer>
    )
  }
}
