import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { CartsContext } from '../Context'
import { InputNumber } from '../UI'
import VoucherValidation from '../VoucherValidation'

const InputNumberContainer = styled.div`
  display: ${(props) => (props.horizontal ? 'flex' : 'block')};
  & .cart-inputs__inputs {
    font-size: 1em !important;
    width: ${(props) => (props.horizontal ? '170px' : 'auto')};
    margin-right: ${(props) => (props.horizontal ? '1.4rem' : '0')};
    margin-bottom: ${(props) => (props.horizontal ? '0' : '0.4rem')};
  }
  & .cart-inputs__button {
    font-size: 1em !important;
    margin: 0 !important;
    padding: 0.9em 1.2em !important;
    width: ${(props) => (props.horizontal ? 'auto' : '100%')};
  }
`

const ProductCartInputs = ({
  productSapCode,
  horizontal,
  onQuantityChange,
  isNarcotic,
  afterVoucherValidation,
  stockStatus = 'empty',
  displayAddButton = true,
  ...restProps
}) => {
  const cart = useContext(CartsContext)
  const [quantity, setQuantity] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  
  const handleChangeQuantity = (quantity) => {
    cart.addOnlineProducts({ quantity: quantity, productSapCode: productSapCode })
    setQuantity(quantity)
    onQuantityChange(parseInt(quantity, 10))
  }

  const validateNarcotic = () => setIsModalOpen(true)

  const handleVoucherValidationSucess = useCallback(
    (voucher) => {
      setIsModalOpen(false)
      handleAddProduct('', voucher)
      afterVoucherValidation()
    },
    // eslint-disable-next-line
    [afterVoucherValidation]
  )

  const handleAddProduct = (_, voucher) => {
    const currentItemData = cart.items[productSapCode]
    const newItemData = currentItemData
      ? {
          ...currentItemData,
          quantity: parseInt(currentItemData.quantity || 0, 10) + (quantity || 0),
        }
      : {
          quantity: parseInt(quantity || 0, 10),
        }

    //attatch code to narcotic to sap
    if (isNarcotic) newItemData.code = voucher

    cart.setProductData(productSapCode, newItemData)
  }

  /* RENDER */
  return (
    <InputNumberContainer horizontal={horizontal} {...restProps}>
      <InputNumber
        value={quantity}
        onChange={handleChangeQuantity}
        className="cart-inputs__inputs"
      />
      {displayAddButton && (
        <Button
          content="Añadir"
          disabled={(!quantity || quantity === '0') || stockStatus === 'empty'}
          onClick={isNarcotic ? validateNarcotic : handleAddProduct}
          className="cart-inputs__button button--green"
        />
      )}
      {isNarcotic && (
        <VoucherValidation
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          productSapCode={productSapCode}
          handleSuccessValidation={handleVoucherValidationSucess}
        />
        
      )}
    </InputNumberContainer>
    
  )
}

ProductCartInputs.propTypes = {
  productSapCode: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  onQuantityChange: PropTypes.func,
  afterVoucherValidation: PropTypes.func,
}

ProductCartInputs.defaultProps = {
  horizontal: false,
  onQuantityChange: (value) => null,
  afterVoucherValidation: () => true,
}

export default ProductCartInputs
