import React, { useContext, useState} from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import 'firebase/database'

import { OrderRequestModal } from './OrderRequestModal'
import { UserContext } from './Context'

import { SEMANTICDELIVERYDAYS } from '../globals'
import { WarehouseContext } from './Context/Warehouse'

const STOCKSTATUSMESSAGE = {
  full: 'Disponible',
  half: 'Incompleto',
  empty: 'Agotado',
}

const DeliveryTimeContainer = styled.div`
  font-size: 0.9rem;
  color: #666;
  border: ${({ productstatus, stockstatus }) =>
    `1px solid ${
      productstatus === 'B'
        ? '#db2828'
        : productstatus === 'E'
        ? '#f2711c'
        : stockstatus === 'full'
        ? '#409142'
        : stockstatus === 'half'
        ? '#f2711c'
        : '#db2828'
    }`};
  border-radius: 0.3rem;
  padding: 0.4rem 0.8rem 0.4rem 0.4rem;
  text-align: center;
  display: inline-block;
  &:hover {
    cursor: ${({ productstatus }) => (productstatus === 'E' ? 'pointer' : 'auto')};
  }
`
const DeliveryTimeIcon = styled(Icon).attrs(() => ({ size: 'large' }))`
  color: ${({ productstatus, stockstatus }) =>
    productstatus === 'B'
      ? '#db2828'
      : productstatus === 'E'
      ? '#f2711c'
      : stockstatus === 'full'
      ? '#409142'
      : stockstatus === 'half'
      ? '#f2711c'
      : '#db2828'};
  margin-right: 0.1em !important;
`
const DeliveryTimeStatus = styled.span`
  color: ${({ productstatus, stockstatus }) =>
    productstatus === 'B'
      ? '#db2828'
      : productstatus === 'E'
      ? '#f2711c'
      : stockstatus === 'full'
      ? '#409142'
      : stockstatus === 'half'
      ? '#f2711c'
      : '#db2828'};
  font-weight: bold;
`

/**
 * Component: DeliveryTime
 * Description.
 */
const DeliveryTime = React.memo(
  ({
    quantity,
    sapCode,
    sapDescription,
    productStatus,
    showQuantities,
    showQuantitiesInNewline,
    stocks,
    stockStatus,
    shipments,
    ...restProps
  }) => {
    const { userRoute } = useContext(UserContext)
    const { getWarehouseByLiteral } = useContext(WarehouseContext)
    //const [stocks, setStocks] = useState(null)
    
    const [isModalOpen, setIstModalOpen] = useState(false)

    if (!userRoute)
      return (
        <DeliveryTimeContainer stockstatus="no-user-route">
          <DeliveryTimeIcon name="warning" stockstatus="no-user-route" />
          <DeliveryTimeStatus stockstatus="no-user-route">
            Almacén principal del usuario no definido
          </DeliveryTimeStatus>
        </DeliveryTimeContainer>
      )

    /*useEffect(() => {
      const productRef = firebase.database().ref(`products/stocks/${parseInt(sapCode, 10)}`)

      // Read current stock from firebase.
      productRef.once('value', (snapshot) => {
        setStocks(snapshot.val())
      })

      const updateStock = (snapshot) => {
        setStocks((prevState) => ({
          ...prevState,
          [snapshot.key]: snapshot.val(),
        }))
      }

      // Update stocks state if there's a firebase stock update.
      productRef.on('child_changed', updateStock)

      return () => {
        productRef.off('child_changed', updateStock)
      }
    }, [sapCode])*/

    /* RENDER */

    if (!stocks) return null

    //if(setStockStatus !== null ) setStockStatus(stockStatus);
    

    

    return (
      <DeliveryTimeContainer
        onClick={productStatus === 'E' ? () => setIstModalOpen(true) : null}
        productstatus={productStatus}
        stockstatus={stockStatus}
        {...restProps}
      >
        {productStatus === 'E' && (
          <OrderRequestModal
            onClose={() => setIstModalOpen(false)}
            onOpen={() => setIstModalOpen(true)}
            open={isModalOpen}
            sapCode={sapCode}
            sapDescription={sapDescription}
          />
        )}
        <DeliveryTimeIcon
          name={
            productStatus === 'B'
              ? 'times'
              : productStatus === 'E'
              ? 'envelope'
              : `thermometer ${stockStatus}`
          }
          productstatus={productStatus}
          stockstatus={stockStatus}
        />
        <DeliveryTimeStatus productstatus={productStatus} stockstatus={stockStatus}>
          {productStatus === 'B'
            ? 'Baja'
            : productStatus === 'E'
            ? 'Encargo'
            : STOCKSTATUSMESSAGE[stockStatus]}
        </DeliveryTimeStatus>
        {productStatus !== 'E' && shipments.length > 0 ? (
          <div
            style={
              showQuantitiesInNewline
                ? { display: 'block', marginTop: '0.4rem' }
                : { display: 'inline', marginLeft: '0.4em' }
            }
          >
            (
            {showQuantities
              ? parse(
                  shipments
                    .map(({ warehouse, quantity, days }) =>
                      quantity === 0
                        ? `<strong>${
                            getWarehouseByLiteral(warehouse).letter
                          }</strong> - ${SEMANTICDELIVERYDAYS[days].toLowerCase()}`
                        : `<strong>${
                            getWarehouseByLiteral(warehouse).letter
                          }</strong>: ${quantity} uds ${SEMANTICDELIVERYDAYS[days].toLowerCase()}`
                    )
                    .join(', ')
                )
              : [...new Set(shipments.map(({ days }) => SEMANTICDELIVERYDAYS[days]))].join(', ')}
            )
          </div>
        ) : null}
      </DeliveryTimeContainer>
    )
  }
)

DeliveryTime.propTypes = {
  quantity: PropTypes.number,
  sapCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productStatus: PropTypes.string,
  showQuantities: PropTypes.bool,
  showQuantitiesInNewline: PropTypes.bool,
  stocks: PropTypes.object,
  stockStatus: PropTypes.string,
  shipments: PropTypes.array,
}

DeliveryTime.defaultProps = {
  quantity: 0,
  productStatus: 'A',
  showQuantities: true,
  showQuantitiesInNewline: false,
  stocks: null,
  stockStatus: 'empty',
  shipments: null,
}

export default DeliveryTime
