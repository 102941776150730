import React, { createContext, useState, useMemo, useCallback } from 'react'

export const MessagesContext = createContext()

export const MessagesProvider = ({ children }) => {
  const [info, setInfo] = useState([])
  const [success, setSuccess] = useState([])
  const [warning, setWarning] = useState([])
  const [error, setError] = useState([])

  const setValue = {
    info: setInfo,
    success: setSuccess,
    warning: setWarning,
    error: setError,
  }

  const setMessages = useCallback(
    (messagesArr, type = 'info') => {
      if (!['info', 'success', 'warning', 'error'].includes(type)) {
        console.error(`Tipo de mensaje (${type}) desconocido`)
        return
      }
      setValue[type](messagesArr)
    },
    [setValue]
  )

  const addMessages = useCallback(
    (messagesArr, type = 'info') => {
      if (!['info', 'success', 'warning', 'error'].includes(type)) {
        console.error(`Tipo de mensaje (${type}) desconocido`)
        return
      }
      setValue[type](prevState => [...prevState, ...messagesArr])
    },
    [setValue]
  )

  const removeMessage = useCallback(
    (index, type = 'info') => {
      if (!['info', 'success', 'warning', 'error'].includes(type)) {
        console.error(`Tipo de mensaje (${type}) desconocido`)
        return
      }
      setValue[type](prevState => prevState.filter((v, i) => i !== index))
    },
    [setValue]
  )

  const resetMessages = useCallback(
    (type = 'info') => {
      if (!['info', 'success', 'warning', 'error'].includes(type)) {
        console.error(`Tipo de mensaje (${type}) desconocido`)
        return
      }
      setValue[type]([])
    },
    [setValue]
  )

  const value = useMemo(
    () => ({
      info,
      success,
      warning,
      error,
      setMessages,
      addMessages,
      removeMessage,
      resetMessages,
    }),
    [info, success, warning, error, setMessages, addMessages, removeMessage, resetMessages]
  )

  return <MessagesContext.Provider value={value}>{children}</MessagesContext.Provider>
}

export function withMessagesContext(Component) {
  return function WithMessagesContextComponent(props) {
    return (
      <MessagesContext.Consumer>
        {globalMessages => {
          return <Component {...props} globalMessages={globalMessages} />
        }}
      </MessagesContext.Consumer>
    )
  }
}
