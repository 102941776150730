import React, { useCallback, useContext, useState } from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  UserContext,
  ApiContext,
  ProductsContext,
  CartsContext,
  PharmaciesContext,
} from './Context'
import Search from './Search'
import { Container } from './styled'

import { normalizeString } from '../utils'

const ImpersonationContainer = styled.div`
  background: #222;
  padding: 0.6rem 0;
  & .impersonation__container {
    position: relative;
  }
  & .impersonation__container__clear {
    position: absolute;
    top: 0.3rem;
    right: 0.1rem;
    z-index: 500;
    color: #000 !important;
    background: transparent !important;
    &:hover {
      color: #fff !important;
    }
  }
  & .impersonation__container__search {
    font-size: 0.9em !important;
    width: 100% !important;
  }
  @media (min-width: 769px) {
    & .impersonation__container {
      display: flex;
      flex-flow: row-reverse nowrap;
    }
    & .impersonation__container__search {
      width: 400px !important;
    }
  }
`

const Impersonation = () => {
  const { userIsImpersonated, userId, userUsername, userName, setUserInfo } =
    useContext(UserContext)
  const { apiRequest } = useContext(ApiContext)
  const { downloadTransfers } = useContext(ProductsContext)
  const { loadCartFromIndexedDbToState } = useContext(CartsContext)
  const { isDownloadingPharmacies, pharmacies } = useContext(PharmaciesContext)

  const [pharmacyId, setPharmacyId] = useState(userIsImpersonated ? userId.toString() : null)
  const [isChangingImpersonation, setIsChangingImpersonation] = useState()

  const handlePharmacySelection = (selectedPharmacyId) => {
    setIsChangingImpersonation(true)
    const previousPharmacyId = pharmacyId
    setPharmacyId(selectedPharmacyId)
    apiRequest(
      selectedPharmacyId ? 'POST' : 'GET',
      selectedPharmacyId ? '/impersonate' : '/stop-impersonate',
      selectedPharmacyId
        ? {
            id: selectedPharmacyId,
          }
        : {}
    )
      .then(({ data: { user } }) => {
        setUserInfo({
          ...user,
          impersonated: selectedPharmacyId ? true : false,
        })
        downloadTransfers()
        loadCartFromIndexedDbToState(user.username)
        setIsChangingImpersonation(false)
      })
      .catch((error) => {
        setPharmacyId(previousPharmacyId)
        console.error(error)
      })
  }

  const handleStopImpersonation = () => {
    handlePharmacySelection('')
  }

  const pharmaciesFilter = useCallback(
    (search, pharmacies) => {
      const searchWords = normalizeString(search).split(' ')

      return pharmacies.filter((pharmacy) => {
        const name = normalizeString(pharmacy.name)
        const username = normalizeString(pharmacy.username)

        for (let i = 0, l = searchWords.length; i < l; i++) {
          const word = searchWords[i]
          if (!name.includes(word) && !username.includes(word)) return false
        }

        return true
      })
    },
    []
  )

  const pharmacyRender = (pharmacy) => {
    return [pharmacy.id, `${pharmacy.name} (${pharmacy.username})`]
  }

  return (
    <ImpersonationContainer>
      <Container className="impersonation__container">
        {pharmacyId && (
          <Button
            icon="close"
            compact
            className="impersonation__container__clear"
            onClick={handleStopImpersonation}
          />
        )}
        <Search
          items={pharmacies}
          itemsFilter={pharmaciesFilter}
          itemRender={pharmacyRender}
          initialSearchValue={userIsImpersonated ? `${userName} (${userUsername})` : ''}
          onResultSelection={handlePharmacySelection}
          nResults={10}
          icon={!pharmacyId ? 'building' : null}
          placeholder="Selecciona una farmacia..."
          loading={isDownloadingPharmacies || isChangingImpersonation}
          disabled={isDownloadingPharmacies || isChangingImpersonation || pharmacyId ? true : false}
          className="impersonation__container__search"
        />
      </Container>
    </ImpersonationContainer>
  )
}

export default Impersonation
