import React from 'react'
import { Icon } from 'semantic-ui-react'

export const GenericLoader = ({ style, ...rest }) => (
  // <Loader
  //   active
  //   inline="centered"
  //   style={{ marginTop: '1rem', ...style }}
  //   {...rest}
  // />
  <div
    style={{
      width: '100%',
      textAlign: 'center',
      // marginTop: '1.4rem',
      // padding: '2rem 0',
      ...style,
    }}
    {...rest}
  >
    <Icon loading size="big" name="spinner" />
  </div>
)
