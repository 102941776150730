import firebase from 'firebase'
import { useState, useEffect, useContext} from 'react'
import calculateShipments from '../calculateShipments'
import { UserContext } from '../components/Context'
import { WarehouseContext } from '../components/Context/Warehouse'


export const useStock = (sapCode,quantity) => {
  // Array containing a media query list for each query
  // eslint-disable-next-line
  const [stocks, setStocks] = useState(null)
  const { userRoute } = useContext(UserContext)
  const { delivery, holidays,getUserWarehouse } = useContext(WarehouseContext)

  //const userWarehouse = WAREHOUSES[userRoute.charAt(0)]
  const userWarehouse = getUserWarehouse( userRoute)
  const warehouseKeys =[]

  useEffect(() => {
    const productRef = firebase.database().ref(`products/stocks/${parseInt(sapCode, 10)}`)

    // Read current stock from firebase.
    productRef.once('value', (snapshot) => {
      setStocks(snapshot.val())
    })

    const updateStock = (snapshot) => {
      setStocks((prevState) => ({
        ...prevState,
        [snapshot.key]: snapshot.val(),
      }))
    }

    // Update stocks state if there's a firebase stock update.
    productRef.on('child_changed', updateStock)

    return () => {
      productRef.off('child_changed', updateStock)
    }
  }, [sapCode,quantity])


  if(!stocks) return [null,'empty',null]

  const [stockStatus, shipments] = calculateShipments(
    quantity,
    userWarehouse?.literal,
    stocks,
    warehouseKeys,
    delivery,
    holidays
  )

  return [stocks,stockStatus,shipments];
}
