import React from 'react'
import { Link } from '@reach/router'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import logo from '../images/logo-cenfarte-no-isotype.svg'

const ImageStyled = styled(Image)`
  width: 90px;
  @media (min-width: 769px) {
    width: 110px;
  }
`

/**
 * Component: HeaderLogo
 * Logo that appears in the header.
 */
const HeaderLogo = React.memo(() => {
  return (
    <Link to="/">
      <ImageStyled src={logo} />
    </Link>
  )
})

export default HeaderLogo
