import React, { useContext } from 'react'
import { Link } from '@reach/router'
import styled, { keyframes } from 'styled-components'

import { CartsContext } from './Context'
import HeaderButton from './HeaderButton'

const bounceAlert = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.3);
  }
  80% {
    opacity: 1;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
`

const CartContainer = styled.span`
  position: relative;
  .cart__status-label {
    background: #db2828;
    color: #fff;
    position: absolute;
    top: -11px;
    right: ${({ nitems }) => (nitems > 99 ? '-7px' : nitems > 9 ? '-3px' : '-1px')};
    border: 1px solid transparent;
    border-radius: 0.5rem;
    font-size: 0.65em;
    line-height: 1;
    padding: 0.2rem ${({ nitems }) => (nitems > 9 ? '0.25rem' : '0.35rem')};
    animation: ${bounceAlert} 0.4s linear 0s 1;
    z-index: 1000;
  }
  .cart__icon {
    padding-left: 0.25em !important;
    padding-right: 0.4em !important;
  }
  @media (min-width: 769px) {
    .cart__status-label {
      top: -14px;
      right: ${({ nitems }) => (nitems > 99 ? '-8px' : nitems > 9 ? '-5px' : '-4px')};
      line-height: 1;
      padding: 0.3rem ${({ nitems }) => (nitems > 9 ? '0.35rem' : '0.45rem')};
    }
    .cart__icon {
      padding-left: 0.45em !important;
      padding-right: 0.6em !important;
    }
  }
`

/**
 * Component: HeaderCart
 * Header cart icon that links to current cart.
 */
const HeaderCart = React.memo(({ ...restProps }) => {
  const { items } = useContext(CartsContext)

  const nItems = Object.values(items).reduce((t, v) => t + v.quantity, 0)

  return (
    <span {...restProps}>
      <Link to="/carrito">
        <CartContainer nitems={nItems}>
          {nItems > 0 && <div className="cart__status-label">{nItems}</div>}
          <HeaderButton icon="cart" className="cart__icon" />
        </CartContainer>
      </Link>
    </span>
  )
})

export default HeaderCart
