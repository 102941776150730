import React, { useContext, useState, useMemo, useEffect } from 'react'
import { useLocation } from '@reach/router'
import DebounceInput from 'react-debounce-input'
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserContext, ProductsContext } from './Context'
import ProductHeaderSearchResult from './Product/HeaderSearchResult'

import { Container } from './styled'

import { useClickOutside } from '../hooks'

import { useFilteredList } from '../hooks/useFilteredList'

const NRESULTS = 10

const HeaderSearchContainer = styled.div`
  position: relative;
  width: 100%;
`
const HeaderSearchResultsContainer = styled.div`
  position: fixed;
  top: ${(props) => (props.isadmin ? '10.9rem' : '7.1rem')};
  left: -1px;
  right: -11px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  @media (min-width: 769px) {
    top: ${(props) => (props.isadmin ? '8.45rem' : '4.6rem')};
  }
`
const HeaderSearchResults = styled.div`
  background: #fff;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
`

/**
 * Component: HeaderSearch
 * Global search input.
 */
const HeaderSearch = ({ ...restProps }) => {
  const { userIsImpersonating, userIsAdmin } = useContext(UserContext)
  const { products, transfers, offers, onlinePromotions, displayPrices } =
    useContext(ProductsContext)
  const [search, setSearch] = useState('')
  const [currentLocation, setCurrentLocation] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    if (currentLocation !== pathname) {
      setSearch('')
      setCurrentLocation(pathname)
    }
  }, [pathname, currentLocation])

  const items = useMemo(() => {
    return [
      ...Object.values(transfers),
      ...Object.values(offers).filter((offer) => !transfers[offer.sap_codigo]),
      ...products.filter(
        (product) =>
          !transfers[product.sap_codigo] &&
          !offers[product.sap_codigo] &&
          onlinePromotions.find((item) => parseInt(item.matnr) === parseInt(product.sap_codigo)) !==
            undefined
      ),
      ...products.filter(
        (product) =>
          !transfers[product.sap_codigo] &&
          !offers[product.sap_codigo] &&
          onlinePromotions.find((item) => parseInt(item.matnr) === parseInt(product.sap_codigo)) ===
            undefined
      ),
    ]
  }, [transfers, offers, products, onlinePromotions])

  const [filteredProducts] = useFilteredList(items, search, 0, NRESULTS)

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleKeys = ({ keyCode }) => {
    // ESC.
    if (keyCode === 27) {
      setSearch('')
    }
  }

  // To empty search when mouse clicking outside the search results list.
  const handleClickOutside = (e) => {
    //Since voucher validation modal is not intrinsic to HeaderSearchResult then we will neeed to validate
    //the model in here instead of just useClockOutside hook
    const voucherValidationModal = document.querySelector('.voucher-validation-modal')
    const orderRequestModal = document.querySelector('#order-request-modal')
    if (voucherValidationModal || orderRequestModal) return
    setSearch('')
  }

  const { ref: resultsListRef } = useClickOutside(handleClickOutside)

  return (
    <HeaderSearchContainer {...restProps}>
      <DebounceInput
        autoComplete="off"
        fluid
        element={Input}
        minLength={1}
        debounceTimeout={200}
        icon="search"
        placeholder="Buscar producto..."
        value={search}
        onChange={handleSearchChange}
        onKeyUp={handleKeys}
        id="header-search-input"
      />
      {filteredProducts && filteredProducts.length > 0 && (
        <HeaderSearchResultsContainer id="test" isadmin={userIsImpersonating() || userIsAdmin()}>
          <Container>
            <HeaderSearchResults ref={resultsListRef}>
              {filteredProducts.map((product) => {
                const onlinePromotionsItem = onlinePromotions.find(
                  (item) => parseInt(item.matnr) === parseInt(product.sap_codigo)
                )

                return (
                  <ProductHeaderSearchResult
                    key={`${product.id}-${product.sap_codigo}`}
                    displayPrices={displayPrices}
                    onlinePromotionsItem={onlinePromotionsItem}
                    afterVoucherValidation={() => setSearch('')}
                    {...product}
                  />
                )
              })}
            </HeaderSearchResults>
          </Container>
        </HeaderSearchResultsContainer>
      )}
    </HeaderSearchContainer>
  )
}

export default HeaderSearch
