import React from 'react'
import { es } from 'date-fns/locale'
import format from 'date-fns/format'
import styled from 'styled-components'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

const HealthAlertsWidgetContainer = styled.div`
  overflow-y: auto;
  max-height: 350px;
  & .health-alerts__empty {
    text-align: center;
    font-style: italic;
    margin: 2rem;
  }
  & .health-alerts__alert {
    display: block;
    color: #000;
    padding: 0.8rem 1.4rem;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #f8f8f8;
    }
  }
  & .health-alerts__alert__title {
    font-size: 1.1em;
    font-weight: bold;
  }
  & .health-alerts__alert__subtitle {
    font-size: 1em;
  }
  & .health-alerts__alert__date {
    font-size: 1em;
    color: #666;
    font-style: italic;
    margin-top: 0.4rem;
  }
`

const HealthAlertsWidget = () => {
  const [data, status] = useFetch(`/aemps-alerts`)

  if (status === 'loading') {
    return <GenericLoader style={{ padding: '2.4rem' }} />
  } else if (status === 'error') {
    return <WidgetError description="No se pueden cargar las últimas alertas" />
  }

  const alerts = data && data.data ? data.data : []

  return (
    <HealthAlertsWidgetContainer>
      {alerts.length > 0 ? (
        alerts.map((alert) => {
          const formattedDate = format(new Date(alert.date), "d' de 'MMMM' de 'yyyy", {
            locale: es,
          })

          return (
            <a
              key={alert.code}
              href={alert.url}
              target="_blank"
              rel="noopener noreferrer"
              className="health-alerts__alert"
            >
              <div className="health-alerts__alert__title">{alert.ref}</div>
              <div className="health-alerts__alert__subtitle">{alert.title}</div>
              <div className="health-alerts__alert__date">{formattedDate}</div>
            </a>
          )
        })
      ) : (
        <div className="health-alerts__empty">No hay alertas</div>
      )}
    </HealthAlertsWidgetContainer>
  )
}

export default HealthAlertsWidget
