import axios from 'axios'

import { APIBASEURL } from './globals'

const myAxios = axios.create({
  baseURL: APIBASEURL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

myAxios.CancelToken = axios.CancelToken
myAxios.isCancel = axios.isCancel

export default myAxios
