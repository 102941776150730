import React from 'react'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

const ExternalPromotionWidgetContainer = styled.div`
  border-radius: inherit;
  & .external-promotion__image {
    border-radius: 0.3rem;
    width: 100%;
  }
`

const ExternalPromotionWidget = React.memo(({ widget = 1 }) => {
  const [data, status] = useFetch(`/restricted/external-promotions?widget_number=${widget}`)

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error')
    return <WidgetError description="No se pueden cargar los datos de la promoción" />

  const { title, image, url, document } = data.data

  return (
    <ExternalPromotionWidgetContainer>
      {url || document ? (
        <a href={url || document} target="_blank" rel="noopener noreferrer" title={title}>
          <Image src={image} className="external-promotion__image" alt={title} />
        </a>
      ) : (
        <Image src={image} className="external-promotion__image" alt={title} />
      )}
    </ExternalPromotionWidgetContainer>
  )
})

export default ExternalPromotionWidget
