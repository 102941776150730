import { useState } from 'react'

export const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)

  const handleValueChange = e => {
    const { type, value, checked } = e.target
    setValue(type === 'checkbox' ? checked : value)
  }

  return { value, onChange: handleValueChange }
}
