import React from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

const CenfarteModal = styled(Modal)`
  width: 90% !important;
  font-size: 0.8rem !important;
  padding: 1.4rem 0 2.8rem 0;
  & div {
    text-align: center;
  }
  & .modal__title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1.2rem;
  }
  & .modal__description {
    padding: 0 15%;
    font-size: 1.3em;
    color: #666;
    margin-bottom: 1.4rem;
  }

  @media (min-width: 376px) {
    width: 350px !important;
  }
  @media (min-width: 769px) {
    font-size: inherit !important;
  }
`

/**
 * Component: VoucherRequest
 * Modal to set voucher code.
 */
export const GenericModal = ({ open, setOpen, title = '', children, ...restProps }) => {
  return (
    <React.Fragment>
      <CenfarteModal
        open={open}
        onClose={() => setOpen(false)}
        size="mini"
        dimmer="blurring"
        {...restProps}
      >
        <Modal.Header>
          <div className="modal__title">{title}</div>
        </Modal.Header>
        <Modal.Content>
          <div>{children}</div>
        </Modal.Content>
      </CenfarteModal>
    </React.Fragment>
  )
}
