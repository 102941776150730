import React from 'react'
import { Icon, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import GlobalStyle from '../components/styled/GlobalStyle'

import logo from '../images/logo-cenfarte-no-isotype.svg'
import logoChrome from '../images/logo-chrome.svg'
import logoFirefox from '../images/logo-firefox.svg'

const InternetExplorerAdviceScreenContainer = styled.div`
  text-align: center;
  margin-top: 3rem;
  .ie-warning__icon {
    font-size: 6em;
    line-height: 1;
    color: #999999;
    margin-bottom: 1rem;
  }
  .ie-warning__logo {
    width: 200px;
  }
  .ie-warning__text {
    text-align: center;
    font-size: 0.9em;
    margin: 1.6em 5%;
  }
  .ie-warning__links {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    a {
      opacity: 0.9;
      display: block;
      margin: 0 1.6rem;
      text-align: center;
    }
    a:hover {
      opacity: 1;
    }
  }
  @media (min-width: 769px) {
    margin: 0 auto;
    width: 70%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    .ie-warning__icon {
      font-size: 10em;
      margin-bottom: 2rem;
    }
    .ie-warning__logo {
      width: 300px;
    }
    .ie-warning__text {
      width: 100%;
      font-size: 1.4em;
      margin: 1.6em 0;
    }
  }
`

const InternetExplorerAdviceScreen = () => {
  return (
    <>
      <GlobalStyle />
      <InternetExplorerAdviceScreenContainer>
        <Icon name="exclamation" className="ie-warning__icon" />
        <Image src={logo} className="ie-warning__logo" centered />
        <div className="ie-warning__text">
          <p>
            Parece que estás utilizando el navegador <strong>Internet Explorer</strong> versión 11 o
            anterior.
          </p>
          <p>
            Se trata de un navegador muy antiguo que no dispone de las últimas funcionalidades
            avanzadas que nos permiten hacer que tu experiencia en la aplicación tenga las máximas
            garantías de calidad y estabilidad.
          </p>
          <p>
            Los sistemas operativos actuales incluyen de serie navegadores más modernos:{' '}
            <i>Microsoft Edge</i> (Windows) y <i>Safari</i> (Mac). Pero si no quieres depender del
            sistema operativo y utilizar un navegador actual con las últimas características,
            nuestra recomendación es que descargues y utilices la última versión de uno de los dos
            navegadores más utilizados actualmente: <strong>Google Chrome</strong> ó{' '}
            <strong>Mozilla Firefox</strong>.
          </p>
          <p>
            En caso de que no los tengas instalados, puedes obtenerlos pulsando en cualquiera de las
            siguientes imágenes:
          </p>
        </div>
        <div className="ie-warning__links">
          <a href="https://www.google.com/intl/es_es/chrome/">
            <Image src={logoChrome} height="80px" centered />
            <div style={{ marginTop: '0.6em' }}>Google Chrome</div>
          </a>
          <a href="https://www.mozilla.org/es-ES/firefox/new/">
            <Image src={logoFirefox} height="80px" centered />
            <div style={{ marginTop: '0.6em' }}>Mozilla Firefox</div>
          </a>
        </div>
      </InternetExplorerAdviceScreenContainer>
    </>
  )
}

export default InternetExplorerAdviceScreen
