import React from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import OrderView from './View'

import { COLOR } from '../../globals'

const Trigger = styled.div`
  font-size: 1.1em;
  line-height: 1;
  font-weight: bold;
  color: ${COLOR};
  &:hover {
    cursor: pointer;
  }
`

const OrderModalTrigger = ({ orderNumber, triggerProps, ...restProps }) => {
  const trimmedOrderNumber = parseInt(orderNumber, 10) || orderNumber
  return (
    <Modal
      closeIcon
      dimmer="blurring"
      trigger={<Trigger {...triggerProps}>{trimmedOrderNumber}</Trigger>}
      {...restProps}
    >
      <Modal.Header>Pedido Nº {trimmedOrderNumber}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <OrderView orderNumber={trimmedOrderNumber} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default OrderModalTrigger
