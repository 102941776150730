import React, { PureComponent, createContext } from 'react'
import axios from 'axios'

import { APIBASEURL } from '../../globals'

export const UserContext = createContext()

export class UserProvider extends PureComponent {
  state = {
    userStatus: 'checking',
    userIsImpersonated: false,
    userImpersonatorId: null,
    userImpersonatorRole: null,
    userId: null,
    userRole: 'user',
    userUsername: null,
    userKunnr: null,
    userEmail: null,
    userName: null,
    userRoute: null,
    userCif: null,
    userAddress: null,
    userCity: null,
    userProvince: null,
    userPostalCode: null,
    setUserInfo: ({
      impersonated: userIsImpersonated,
      impersonatorId: userImpersonatorId,
      impersonatorRole: userImpersonatorRole,
      id: userId,
      role: userRole,
      username: userUsername,
      pharmacy: {
        kunnr: userKunnr,
        email: userEmail,
        name: userName,
        route: userRoute,
        cif: userCif,
        address: userAddress,
        city: userCity,
        state: userProvince,
        zip: userPostalCode,
      },
    }) => {
      this.setState({
        userStatus: 'logged-in',
        userIsImpersonated,
        ...(userImpersonatorId ? { userImpersonatorId } : {}),
        ...(userImpersonatorRole ? { userImpersonatorRole } : {}),
        userId,
        userRole: userRole || 'user',
        userUsername,
        userKunnr,
        userEmail,
        userName,
        userRoute,
        userCif,
        userAddress,
        userCity,
        userProvince,
        userPostalCode,
      })
    },
    updateUserInfo: userData => {
      this.setState({ ...userData })
    },
    checkUserStatus: () => {
      axios({
        method: 'GET',
        url: `${APIBASEURL}/logged`,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(({ data }) => {
          if (data.logged) {
            this.state.setUserInfo({
              ...data.user,
              impersonated: data.impersonated,
              impersonatorId: data.impersonated ? data.original.id : data.user.id,
              impersonatorRole: data.impersonated ? data.original.role : data.user.role,
            })
          } else {
            this.setUserLoggedOut()
          }
        })
        .catch(error => {
          console.error(error)
          this.setUserLoggedOut()
        })
    },
    userIsImpersonating: () => {
      return this.state.userIsImpersonated && this.state.userImpersonatorRole === 'admin'
    },
    userIsAdmin: () => {
      return this.state.userRole === 'admin'
    },
    userIsManager: () => {
      return this.state.userRole === 'manager'
    },
    userIsUser: () => {
      return this.state.userRole === 'user'
    },
    logoutUser: () => {
      axios({
        method: 'GET',
        url: `${APIBASEURL}/logout`,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          this.setUserLoggedOut()
        })
        .catch(error => {
          console.error(error)
          this.setUserLoggedOut()
        })
    },
  }

  setUserLoggedOut = () => {
    this.setState({
      userStatus: 'logged-out',
      userIsImpersonated: false,
      userImpersonatorId: null,
      userImpersonatorRole: null,
      userId: null,
      userRole: 'user',
      userUsername: null,
      userKunnr: null,
      userEmail: null,
      userName: null,
      userRoute: null,
      userCif: null,
      userAddress: null,
      userCity: null,
      userProvince: null,
      userPostalCode: null,
    })
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>
  }
}

export const UserConsumer = UserContext.Consumer

export function withUserContext(Component) {
  return function WithUserContextComponent(props) {
    return (
      <UserContext.Consumer>{user => <Component {...props} user={user} />}</UserContext.Consumer>
    )
  }
}
