import React from 'react'
import styled from 'styled-components'

const WidgetContainerStyled = styled.div`
  border-radius: 0.3rem;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  & .widget-container__title {
    background: #eee;
    padding: 0.8rem 1.4rem;
    font-size: 1.1em;
    color: #444;
  }
`

const WidgetContainer = React.memo(({ title, children, ...restProps }) => {
  return (
    <WidgetContainerStyled {...restProps}>
      {title && <div className="widget-container__title">{title}</div>}
      {children}
    </WidgetContainerStyled>
  )
})

export default WidgetContainer
