import React from 'react'
import { Link } from '@reach/router'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Container } from './styled'

import { COLOR } from '../globals'

import logoWhite from '../images/logo-cenfarte-white.svg'

const StyledFooter = styled.footer`
  background: ${COLOR};
  color: #fff;
  font-size: 0.9rem;
  padding: 1rem 0 3rem 0;
  @media (min-width: 769px) {
    font-size: 1.1rem;
    padding: 2rem 0 2.4rem 0;
  }
`
const FooterItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  & #footer-links {
    margin-top: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    & > div {
      margin-right: 1.2rem;
    }
  }
  & .footer-links-title {
    font-size: 0.8em;
    color: #a4daa6;
    padding-bottom: 0em;
  }
  & .footer-links-link {
    display: block;
    font-size: 1.1em;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  & #footer-logo {
    width: 100px;
    opacity: 0.6;
    @media (min-width: 769px) {
      width: 150px;
    }
  }
  @media (min-width: 769px) {
    & #footer-links {
      & > div {
        margin-right: 3rem;
      }
    }
    & .footer-links-title {
      padding-bottom: 0.4em;
    }
  }
`

/**
 * Component: Footer
 * Global application footer.
 */
const Footer = React.memo(() => {
  return (
    <StyledFooter>
      <Container>
        <FooterItems>
          <div id="footer-links">
            <div>
              {/* <div className="footer-links-title">CENFARTE</div> */}
              <a
                className="footer-links-link"
                href="https://www.cenfarte.es"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Oficial
              </a>
            </div>
            <div>
              {/* <div className="footer-links-title">INFORMACIÓN</div> */}
              <a
                className="footer-links-link"
                href="https://www.cenfarte.es/noticias"
                target="_blank"
                rel="noopener noreferrer"
              >
                Noticias
              </a>
            </div>
            <div>
              {/* <div className="footer-links-title">&nbsp;</div> */}
              <Link to="/cookies" className="footer-links-link">
                Cookies
              </Link>
            </div>
          </div>
          <Image src={logoWhite} id="footer-logo" />
        </FooterItems>
      </Container>
    </StyledFooter>
  )
})

export default Footer
