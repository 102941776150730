import React, { useContext, createRef, useState, useEffect } from 'react'
import { Modal, Icon, Input, Form, Button, TextArea } from 'semantic-ui-react'
import styled from 'styled-components'

import { COLOR } from '../globals'
import { ApiContext } from './Context'

const StyledModal = styled(Modal)`
  width: 90% !important;
  font-size: 0.8rem !important;
  padding: 1.4rem 0 2.8rem 0;
  & div {
    text-align: center;
  }
  & .modal_icon {
    font-size: 2.8em;
    margin: 1.4rem 0 1.8rem 0;
    padding: 0.6rem !important;
    box-shadow: none !important;
    color: #56ae5b;
    background: #c2eac6;
  }
  & .modal_title {
    font-size: 1.9em;
    font-weight: bold;
    margin-bottom: 1.2rem;
  }
  & .modal_description {
    font-size: 1.3em;
    color: #666;
    margin-bottom: 1.4rem;
  }

  button {
    font-size: 1em !important;
    margin: 0 !important;
    padding: 0.9em 1.2em !important;
    width: ${(props) => (props.horizontal ? 'auto' : '100%')};
    background: ${COLOR} !important;
    color: #fff !important;
  }

  @media (min-width: 376px) {
    width: 350px !important;
  }
  @media (min-width: 769px) {
    font-size: inherit !important;
  }
`

export const OrderRequestModal = ({ open, sapCode = '', sapDescription = '', ...restProps }) => {
  const { postRequest } = useContext(ApiContext)
  const [matnr, setMatnr] = useState(sapCode)
  const [description, setDescription] = useState(sapDescription)
  const [units, setUnits] = useState('')
  const [observations, setObservations] = useState('')
  const filesInput = createRef()
  const [requestStatus, setRequestStatus] = useState('ready')
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    setRequestStatus('ready')
    setObservations('')
    setUnits('')
  }, [open])

  useEffect(() => {
    setCanSubmit(description && units)
  }, [description, units])

  const handleMatrnChange = (event) => {
    setMatnr(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleUnitsChange = (event) => {
    const { value } = event.target
    setUnits(value)
  }

  const handleObservationsChange = (event) => {
    const { value } = event.target
    setObservations(value)
  }

  const handlePost = (e) => {
    e.preventDefault()
    setRequestStatus('pending')

    const requestData = {
      matnr,
      description,
      observations,
      quantity: units,
      attachment: filesInput.current.files[0],
    }

    const formData = new FormData()
    Object.entries(requestData).map(([key, value]) => formData.append(key, value))

    postRequest('/charges', formData)
      .then((res) => {
        setRequestStatus('success')
        setUnits('')
        setObservations('')
      })
      .catch((err) => {
        setRequestStatus('error')
      })
  }

  return (
    <StyledModal id="order-request-modal" size="mini" dimmer="blurring" open={open} {...restProps}>
      <Modal.Header>
        <div>
          <Icon name="ticket alternate" className="modal_icon" circular />
        </div>
        <div className="modal_title">Solicitud de encargos</div>
      </Modal.Header>
      <Modal.Content>
        <div className="modal_description">
          <p>Rellena los siguientes campos:</p>
          {requestStatus === 'success' && (
            <div className="ui green message">
              Se ha realizado el encargo. Recibirás un correo de confirmación.
            </div>
          )}
          {requestStatus === 'error' && (
            <div className="ui red message">Ha ocurrido un error realizando el encargo.</div>
          )}
        </div>
        <div>
          <Form onSubmit={handlePost}>
            <Form.Field>
              <Input
                type="number"
                name="matnr"
                placeholder="Código nacional"
                min="0"
                value={matnr ? parseInt(matnr, 10) : ''}
                onChange={handleMatrnChange}
                disabled={!!sapCode}
                fluid
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="text"
                name="description"
                placeholder="Descripción"
                value={description}
                onChange={handleDescriptionChange}
                disabled={!!sapCode}
                fluid
                required
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                required
                type="number"
                lang="es"
                name="units"
                placeholder="Unidades"
                min="0"
                value={units}
                onChange={handleUnitsChange}
              />
            </Form.Field>
            <Form.Field>
              <TextArea
                placeholder="Observaciones"
                rows={3}
                value={observations}
                onChange={handleObservationsChange}
              />
            </Form.Field>
            <Form.Field>
              <input ref={filesInput} type="file" placeholder="Adjuntar archivo" />
            </Form.Field>
            <Button
              type="submit"
              disabled={requestStatus === 'pending' || !canSubmit}
              loading={requestStatus === 'pending'}
            >
              Encargar
            </Button>
          </Form>
        </div>
      </Modal.Content>
    </StyledModal>
  )
}
