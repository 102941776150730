import React, { useContext } from 'react'
import { Location, Link } from '@reach/router'
import { Menu, Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserContext } from './Context'

import { useMedia } from '../hooks'

import { MENUOPTIONS, COLOR } from '../globals'

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  .main-menu__dropdown {
    background: ${COLOR} !important;
    color: #fff !important;
    border-color: ${COLOR} !important;
    & * {
      background: ${COLOR} !important;
      color: #fff !important;
    }
  }
  .main-menu__button {
    background-color: #e9e9e9 !important;
    margin: 0 0.4rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .main-menu__button--active {
    background-color: ${COLOR} !important;
    color: #fff !important;
  }
  .main-menu__button--dropdown__option--active span {
    color: ${COLOR} !important;
    font-weight: bold;
  }
  @media (min-width: 1024px) {
    .main-menu__button {
      margin: 0 0.25rem !important;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  @media (min-width: 1280px) {
    margin-bottom: 1rem;
    .main-menu__button {
      margin: 0 0.5rem !important;
      padding-left: 1.3rem !important;
      padding-right: 1.3rem !important;
    }
  }
  @media (min-width: 1441px) {
    .main-menu__button {
      margin: 0 0.6rem !important;
      padding-left: 1.4rem !important;
      padding-right: 1.4rem !important;
    }
  }
`

/**
 * Component: MainMenu
 * Main application menu.
 */
const MainMenu = React.memo(() => {
  const { userIsImpersonating, userIsAdmin, userIsManager } = useContext(UserContext)
  const menuSize = useMedia(['(min-width: 1440px)'], ['huge'], 'large')

  const filterMenuOptions = (menuOptions) => {
    if (userIsAdmin() || userIsManager()) return Object.values(menuOptions)

    return Object.values(menuOptions).reduce((t, option) => {
      // If is a single menu option, just check if the user has persmission to access.
      if (!option.group) {
        if (option.isPublic) t.push(option)
        return t
      }

      // If it's a group, filter the option list to get the ones that the user
      // has persmission to access.
      const filteredOptions = Object.values(option.options).filter(
        (suboption) => suboption.isPublic
      )
      if (filteredOptions.length > 1) t.push({ ...option, options: filteredOptions })
      else if (filteredOptions.length === 1) t.push(filteredOptions[0])
      return t
    }, [])
  }

  return (
    <Location>
      {({ location }) => {
        const currentRouteBase = location.pathname.split('/')[1]
        const menuOptionsArray = filterMenuOptions(MENUOPTIONS)

        return (
          <MenuContainer isadmin={userIsImpersonating() || userIsAdmin()}>
            <Menu as="nav" secondary size={menuSize}>
              {menuOptionsArray.map((menuOption) => {
                /* MENU GROUP OPTION */
                if (menuOption.group) {
                  const groupOptions = Object.values(menuOption.options)
                  const groupUrls = groupOptions.map((v) => v.url)

                  return (
                    <Dropdown
                      key={menuOption.group}
                      item
                      text={menuOption.group}
                      className={`main-menu__button${
                        groupUrls.includes(currentRouteBase) ? ' main-menu__button--active' : ''
                      }`}
                    >
                      <Dropdown.Menu>
                        {groupOptions.map((option) => (
                          <Dropdown.Item
                            key={option.url}
                            as={Link}
                            to={option.url}
                            {...(currentRouteBase === option.url
                              ? { className: 'main-menu__button--dropdown__option--active' }
                              : {})}
                          >
                            <span>{option.text}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }

                /* MENU SINGLE OPTION */
                return (
                  <Menu.Item
                    key={menuOption.url}
                    as={Link}
                    to={menuOption.url}
                    name={menuOption.text}
                    content={menuOption.text}
                    active={currentRouteBase === menuOption.url}
                    className={`main-menu__button${
                      currentRouteBase === menuOption.url ? ' main-menu__button--active' : ''
                    }`}
                  />
                )
              })}
            </Menu>
          </MenuContainer>
        )
      }}
    </Location>
  )
})

export default MainMenu
