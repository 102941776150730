import React, { useContext, useEffect, useState } from 'react'
import { Modal, Icon, Input, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

// import { useFormInput } from '../hooks'

import { COLOR } from '../globals'

import 'text-security/dist/text-security-disc.css'
import { ApiContext } from './Context'

const VoucherValidationModal = styled(Modal)`
  width: 90% !important;
  font-size: 0.8rem !important;
  padding: 1.4rem 0 2.8rem 0;
  & div {
    text-align: center;
  }
  & .voucher-request__icon {
    font-size: 2.8em;
    margin: 1.4rem 0 1.8rem 0;
    padding: 0.6rem !important;
    box-shadow: none !important;
    color: #56ae5b;
    background: #c2eac6;
  }
  & .voucher-request__title {
    font-size: 1.9em;
    font-weight: bold;
    margin-bottom: 1.2rem;
  }
  & .voucher-request__description {
    font-size: 1.3em;
    color: #666;
    margin-bottom: 1.4rem;
  }
  & .voucher-request__input {
    & button {
      font-size: 1.2em;
      color: #fff;
      background: ${COLOR} !important;
      padding: 0 1.2rem !important;
    }
  }
  @media (min-width: 376px) {
    width: 350px !important;
  }
  @media (min-width: 769px) {
    font-size: inherit !important;
  }
`

//This value commes from SAP, to check on documentation go to ticket
//https://soporte.iteisa.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=46433#209362
// and check on the attached file.
const VALID_VOUCHER_STATUS = 'D'

/**
 * Component: VoucherValidation
 * Modal to prevent narcotics add to cart action without voucher.
 */
const VoucherValidation = ({ handleSuccessValidation, productSapCode, ...restProps }) => {
  const { postRequest } = useContext(ApiContext)
  const [voucher, setVoucher] = useState('')
  const [requestStatus, setRequestStatus] = useState('pending')

  useEffect(() => {
    if (requestStatus !== 'success') return

    const timeoutId = setTimeout(() => {
      setRequestStatus('pending')
      handleSuccessValidation(voucher)
    }, 500)

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [requestStatus, handleSuccessValidation, voucher])

  /* HANDLERS */
  // When user clicks 'Enter' key.
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) validateVoucher()
  }

  const validateVoucher = () => {
    setRequestStatus('requesting')
    postRequest(`/narcotics/validate`, { matnr: productSapCode, code: voucher })
      .then((response) => {
        if (response.data.data.ESTADO === VALID_VOUCHER_STATUS) {
          setRequestStatus('success')
          return
        }

        setRequestStatus('error')
      })
      .catch((error) => {
        console.error(error)
        setRequestStatus('error')
      })
  }

  return (
    <VoucherValidationModal
      size="mini"
      dimmer="blurring"
      className="voucher-validation-modal"
      {...restProps}
    >
      <Modal.Header>
        <div>
          <Icon name="ticket alternate" className="voucher-request__icon" circular />
        </div>
        <div className="voucher-request__title">Estupefaciente</div>
      </Modal.Header>
      <Modal.Content>
        <div className="voucher-request__description">
          <p>Introduce el vale para el artículo con código {parseInt(productSapCode, 10)}.</p>
          {requestStatus === 'success' && (
            <div className="ui green message">
              El vale es correcto, añadiendo al carrito <Loader active inline inverted />
            </div>
          )}
          {requestStatus === 'error' && (
            <div className="ui red message">
              Error en el vale, revisar texto introducido. No se añade al carrito.
            </div>
          )}
        </div>
        <div>
          <Input
            vaue={voucher}
            onChange={({ target }) => setVoucher(target.value)}
            onKeyUp={handleKeyUp}
            name="voucher"
            autoFocus="autofocus"
            autoComplete="voucher-validation"
            size="big"
            action={{
              icon: 'arrow right',
              disabled: !voucher,
              loading: requestStatus === 'requesting',
              onClick: validateVoucher,
            }}
            type="text"
            className="voucher-request__input"
          />
        </div>
      </Modal.Content>
    </VoucherValidationModal>
  )
}

export default VoucherValidation
