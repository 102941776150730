import React from 'react'
import { Link } from '@reach/router'
import { Image, Icon } from 'semantic-ui-react'
import DOMPurify from 'dompurify'
import styled from 'styled-components'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { es } from 'date-fns/locale'
import productImagePlaceholder from '../../images/product-placeholder.svg'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

import { COLOR } from '../../globals'

const Promotion = styled.div`
  a {
    display: grid;
    grid-template-columns: 100px 1fr;
    border-bottom: 1px solid #f2f2f2;
  }

  img {
    padding: 0.8rem 1.4rem;
    width: 100px;
  }

  h2 {
    color: ${COLOR};
    padding: 0;
    margin: 0;
    font-size: 1.1em;
    font-weight: bold;
  }

  h3 {
    font-size: 10px;
    font-weight: inherit;
    padding: 0;
    color: #b0b0b0;
    margin: 3px 0;
  }
  div {
    padding: 0.5rem;
  }

  &:hover {
    background: #e9e9e9;
  }
`
const OnlinePromotionWidgetContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
  & .events__empty {
    text-align: center;
    font-style: italic;
    margin: 2rem;
  }

  & .onlinePromotions__link {
    display: block;
    border-top: 1px solid #eee;
    text-align: right;
    padding: 1rem 1.6rem;
    font-size: 1.1em;

    &:hover {
      background: #e5e5e5;
    }

    i {
      color: #888;
      margin-right: 0.6rem;
    }
  }

  .onlinePromotions__empty {
    text-align: center;
    font-style: italic;
    padding: 2rem;
  }
`

const OnlinePromotionWidget = React.memo(({ widgetId, ...restProps }) => {
  const [data, status] = useFetch(`/online-promotions?featured=1`)

  if (status === 'loading') {
    return <GenericLoader style={{ padding: '2.4rem' }} />
  }

  if (status === 'error') {
    return <WidgetError description="No se pueden cargar los datos del widget" />
  }

  if (!data || !data.data) {
    return <WidgetError description="El widget no devuelve datos" />
  }
  const promotions = data.data

  return (
    <OnlinePromotionWidgetContainer>
      {promotions.length > 0 ? (
        promotions.map((promotion) => (
          <Promotion key={promotion.id}>
            <Link to={`/online/${promotion.id}`}>
              {promotion.image ? (
                <Image
                  src={promotion.image}
                  alt={`Imagen de la promoción ${promotion.name}`}
                  className="widget-content__image"
                />
              ) : (
                <img
                  src={productImagePlaceholder}
                  className="widget-content__image"
                  alt="Imagen genérica"
                />
              )}

              <div key={promotion.name}>
                <h2>{promotion.name}</h2>
                <h3>
                  Disponible hasta el{' '}
                  {format(parseISO(promotion.end_date), "dd' de 'MMMM' de 'yyyy", {
                    locale: es,
                  })}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(promotion.description),
                  }}
                />
              </div>
            </Link>
          </Promotion>
        ))
      ) : (
        <div className="onlinePromotions__empty">No hay promociones online</div>
      )}
      <Link to="/ofertas" className="onlinePromotions__link">
        <Icon name="certificate" />
        Ver más promociones
      </Link>
    </OnlinePromotionWidgetContainer>
  )
})

export default OnlinePromotionWidget
