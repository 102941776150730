import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'

import { UserContext } from './Context'
import Impersonation from '../components/Impersonation'
import HeaderLogo from './HeaderLogo'
import HeaderSearch from './HeaderSearch'
import HeaderPhone from './HeaderPhone'
import HeaderDisplayPrices from './HeaderDisplayPrices'
import HeaderDisplayStock from './HeaderDisplayStock'
import HeaderCart from './HeaderCart'
import HeaderMenu from './HeaderMenu'
import HeaderButton from './HeaderButton'
import HeaderHamburgerMenu from './HeaderHamburgerMenu'
import MainMenu from './MainMenu'
import { Container } from './styled'

import { useMedia } from '../hooks'

import { COLOR } from '../globals'

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 800;
  border-bottom: 1px solid #ddd;
  background: #ffffff;
  color: #333333;
  font-size: 1.1rem;
`
const HeaderItemsContainer = styled.div`
  border-top: 5px solid ${COLOR};
`
const HeaderItems = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0;
  .header__search {
    flex-basis: 45%;
  }
  .header__button {
    margin-right: 0.7em !important;
  }
  @media (max-width: 768px) {
    padding: 0.8rem 0;
    max-height: ${({ issearchopen }) => (issearchopen ? '100px' : '45px')};
    transition: max-height 0.2s ease;
    .header__search {
      opacity: ${({ issearchopen }) => (issearchopen ? '1' : '0')};
      transition: ${({ issearchopen }) =>
        issearchopen ? 'opacity 0.2s ease 0.05s' : 'opacity 0.1s ease'};
      /* transform: ${({ issearchopen }) => (issearchopen ? 'scaleY(1)' : 'scaleY(0)')};
      transform-origin: top;
      transition: transform 0.2s ease; */
      flex-basis: 100%;
      order: 10;
      margin-top: 0.6rem;
    }
    .header__button {
      margin-right: 0.5em !important;
    }
  }
`
const HeaderTopMargin = styled.div`
  transition: margin-top 0.2s ease;
  margin-top: ${({ isadmin, issearchopen }) =>
    `${isadmin ? (issearchopen ? '11.5' : '8') : issearchopen ? '7.5' : '4'}rem`};
  @media (min-width: 769px) {
    margin-top: ${({ isadmin }) => `${isadmin ? '10.5' : '6'}rem`};
  }
`

/**
 * Component: Header
 * Global application header.
 */
const Header = ({ ...restProps }) => {
  const { userIsImpersonating, userIsAdmin } = useContext(UserContext)
  const minWidth = useMedia(['(min-width: 1024px)', '(min-width: 769px)'], [1024, 769], 0)
  const [isSearchOpen, setIsSearchOpen] = useState(minWidth === 769 ? true : false)

  useEffect(() => {
    if (minWidth === 769) setIsSearchOpen(true)
    else setIsSearchOpen(false)
  }, [minWidth])

  const handleSearchToggle = () => {
    setIsSearchOpen(prevState => !prevState)
  }

  return (
    <>
      <HeaderContainer {...restProps}>
        {(userIsImpersonating() || userIsAdmin()) && <Impersonation />}
        <HeaderItemsContainer>
          <Container>
            <HeaderItems issearchopen={isSearchOpen}>
              <HeaderLogo />
              <div className="header__search">
                <HeaderSearch />
              </div>
              <div>
                {!minWidth && (
                  <HeaderButton
                    icon="search"
                    isActive={isSearchOpen}
                    className="header__button"
                    onClick={handleSearchToggle}
                  />
                )}
                <HeaderPhone className="header__button" />
                <HeaderDisplayStock className="header__button" />
                <HeaderDisplayPrices className="header__button" />
                <HeaderCart className="header__button" />
                {minWidth === 1024 ? <HeaderMenu /> : <HeaderHamburgerMenu />}
              </div>
            </HeaderItems>
          </Container>
        </HeaderItemsContainer>
      </HeaderContainer>
      <HeaderTopMargin
        isadmin={userIsImpersonating() || userIsAdmin()}
        issearchopen={isSearchOpen}
      />
      {minWidth === 1024 && (
        <Container>
          <MainMenu />
        </Container>
      )}
    </>
  )
}

export default Header
