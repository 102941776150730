import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const WidgetErrorContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: #888;
  & .widget-error__icon {
    font-size: 3.2em !important;
    line-height: 1 !important;
    margin-right: 1rem;
    color: #db2828;
  }
  & .widget-error__title {
    font-weight: bold;
    font-size: 1.1em;
  }
`

const WidgetError = ({ icon, title, description, ...restProps }) => {
  return (
    <WidgetErrorContainer {...restProps}>
      <Icon name={icon} className="widget-error__icon" />
      <div>
        <div className="widget-error__title">{title}</div>
        <div>{description}</div>
      </div>
    </WidgetErrorContainer>
  )
}

WidgetError.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

WidgetError.defaultProps = {
  icon: 'warning circle',
  title: 'ERROR',
  description: 'Se ha producido un error.',
}

export default WidgetError
