import React, { useContext } from 'react'

import { ProductsContext } from './Context'
import HeaderButton from './HeaderButton'

/**
 * Component: HeaderDisplayStock
 * Header cart icon that links to current cart.
 */
const HeaderDisplayStock = React.memo(({ ...restProps }) => {
  const { displayStock, setDisplayStockStatus } = useContext(ProductsContext)

  const handleChangeDisplayStock = () => {
    setDisplayStockStatus(!displayStock)
  }

  return (
    <HeaderButton
      icon="box"
      title="Oculta los productos sin stock del buscador y el listado"
      id="display-stock-products-button"
      isActive={displayStock}
      onClick={handleChangeDisplayStock}
      {...restProps}
    />
  )
})

export default HeaderDisplayStock
