import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { formatCurrency } from '../../utils'

const ProductPriceStyled = styled.div`
  font-size: 1em;
  & .product-price__label {
    font-size: 0.9em;
  }
  & .product-price__price {
    font-size: 1.1em;
    font-weight: bold;
  }
  & .product-price__original-price {
    font-size: 0.85em;
    line-height: 1;
    margin-top: 0.1em;
    text-decoration: line-through;
  }
`

const ProductPrice = ({
  label,
  price,
  discount,
  noCurrencyFormat,
  ...restProps
}) => {
  return (
    <ProductPriceStyled {...restProps}>
      <div className="product-price__label">{label}</div>
      <div className="product-price__price">
        {!noCurrencyFormat
          ? formatCurrency(discount > 0 ? price * (1 - discount / 100) : price)
          : price}
      </div>
      {discount > 0 && (
        <div className="product-price__original-price">
          {!noCurrencyFormat ? formatCurrency(price) : price}
        </div>
      )}
    </ProductPriceStyled>
  )
}

ProductPrice.propTypes = {
  label: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discount: PropTypes.number,
  noCurrencyFormat: PropTypes.bool,
}

ProductPrice.defaultPrice = {
  noCurrencyFormat: false,
}

export default ProductPrice
