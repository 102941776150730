import React from 'react'
import PropTypes from 'prop-types'
import { Location, Link } from '@reach/router'
import { Transition } from 'react-transition-group'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserContext } from './Context'
import HeaderButton from './HeaderButton'
import PharmacyInfo from './PharmacyInfo'

import { useLeftMenu } from '../hooks'

import { MENUOPTIONS, COLOR } from '../globals'

import logo from '../images/logo-cenfarte-no-isotype-white.svg'

/**
 * Component: Menu
 *
 */
const Dimmer = styled.div`
  transition: background 0.1s ease-out;
  background: ${({ state }) => (state === 'entered' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)')};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`
const MenuContainer = styled.div`
  transition: transform 0.1s ease-out;
  transform: ${({ state }) =>
    state === 'entered' ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'};
  width: 70%;
  height: 100%;
  background: #2f2f2f;
  color: #e2e2e2;
  font-size: 0.95em;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  & * {
    user-select: none;
  }
  .logo {
    padding: 1em 1.2em;
    border-bottom: 1px solid #555555;
    img {
      height: 1.2em;
    }
  }
  .content {
    flex: 1 1 auto;
    overflow-y: auto;
    overscroll-behavior: contain;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .section {
    padding: 0.5em 0;
    border-bottom: 1px solid #555555;
  }
  .buttons-bar {
    padding: 1em;
    margin-bottom: 2em;
  }
  .buttons-bar__button {
    color: #ffffff !important;
    background: ${COLOR} !important;
  }
`
const MenuGroup = styled.div`
  .title {
    padding: 1em 1.2em 0.6em 1.2em;
  }
`
const MenuOption = styled(Link)`
  padding: 1em 1.2em;
  display: block;
  color: ${({ active }) => (active ? COLOR : '#e2e2e2')} !important;
  background: ${({ active }) => (active ? '#3f3f3f' : '#2f2f2f')} !important;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-right: 1em;
  /* -webkit-tap-highlight-color: #e2e2e2; */
  &:active,
  &:visited {
    color: #e2e2e2;
    background: #2f2f2f;
  }
  &.suboption {
    padding: 0.8em 1.2em 0.8em 2.6em;
    font-size: 0.9em;
  }
`
const Menu = ({ isOpen, onStatusChange }) => {
  const { userIsAdmin, userIsManager, logoutUser } = React.useContext(UserContext)
  const dimmerRef = React.useRef(null)
  const {
    touchContainerRef,
    touchScrollableContentRef,
    handleMenuTouchStart,
    handleMenuTouchMove,
    handleMenuTouchEnd,
    resetTouchEvents,
  } = useLeftMenu(onStatusChange)

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''
  }, [isOpen])

  const handleOptionSelection = (e) => {
    onStatusChange(false)
    resetTouchEvents()
  }

  const handleCloseMenu = (e) => {
    if (e.target === dimmerRef.current) {
      onStatusChange(false)
      resetTouchEvents()
    }
  }

  const handleLogOut = () => {
    logoutUser()
  }

  const filterMenuOptions = (menuOptions) => {
    if (userIsAdmin() || userIsManager()) return Object.values(menuOptions)

    return Object.values(menuOptions).reduce((t, option) => {
      // If is a single menu option, just check if the user has persmission to access.
      if (!option.group) {
        if (option.isPublic) t.push(option)
        return t
      }

      // If it's a group, filter the option list to get the ones that the user
      // has persmission to access.
      const filteredOptions = Object.values(option.options).filter(
        (suboption) => suboption.isPublic
      )
      if (filteredOptions.length > 1) t.push({ ...option, options: filteredOptions })
      else if (filteredOptions.length === 1) t.push(filteredOptions[0])
      return t
    }, [])
  }

  return (
    <Location>
      {({ location }) => {
        const currentRouteBase = location.pathname.split('/')[1]
        const menuOptionsArray = filterMenuOptions(MENUOPTIONS)

        return (
          <Transition in={isOpen} timeout={200}>
            {(state) => {
              return (
                <Dimmer ref={dimmerRef} state={state} onClick={handleCloseMenu}>
                  <MenuContainer
                    ref={touchContainerRef}
                    state={state}
                    onTouchStart={handleMenuTouchStart}
                    onTouchMove={handleMenuTouchMove}
                    onTouchEnd={handleMenuTouchEnd}
                  >
                    <div className="logo">
                      <img src={logo} alt="Logo de Cenfarte" />
                      <PharmacyInfo />
                    </div>
                    <div ref={touchScrollableContentRef} className="content">
                      <div className="section">
                        {menuOptionsArray.map((menuOption) => {
                          if (menuOption.group) {
                            const groupOptions = Object.values(menuOption.options)

                            return (
                              <MenuGroup key={menuOption.group}>
                                <div className="title">{menuOption.group}</div>
                                {groupOptions.map((option) => (
                                  <MenuOption
                                    key={option.url}
                                    to={option.url}
                                    className="suboption"
                                    active={currentRouteBase === option.url ? 1 : 0}
                                    onClick={handleOptionSelection}
                                  >
                                    {option.text}
                                  </MenuOption>
                                ))}
                              </MenuGroup>
                            )
                          }

                          return (
                            <MenuOption
                              key={menuOption.url}
                              to={menuOption.url}
                              active={currentRouteBase === menuOption.url ? 1 : 0}
                              onClick={handleOptionSelection}
                            >
                              {menuOption.text}
                            </MenuOption>
                          )
                        })}
                      </div>
                      {(userIsAdmin() || userIsManager()) && (
                        <div className="section">
                          <MenuOption
                            to="/usuarios"
                            active={currentRouteBase === 'usuarios' ? 1 : 0}
                            onClick={handleOptionSelection}
                          >
                            Mis usuarios
                          </MenuOption>
                          <MenuOption
                            to="/farmacia"
                            active={currentRouteBase === 'farmacia' ? 1 : 0}
                            onClick={handleOptionSelection}
                          >
                            Cambiar contraseña
                          </MenuOption>
                        </div>
                      )}
                      <div className="buttons-bar">
                        <Button
                          fluid
                          className="buttons-bar__button"
                          icon="log out"
                          content="Desconectar"
                          onClick={handleLogOut}
                        />
                      </div>
                    </div>
                  </MenuContainer>
                </Dimmer>
              )
            }}
          </Transition>
        )
      }}
    </Location>
  )
}
Menu.propTypes = {
  isOpen: PropTypes.bool,
  onStatusChange: PropTypes.func,
}
Menu.defaultProps = {
  isOpen: false,
  onStatusChange: () => null,
}

/**
 * Component: HeaderHamburgerMenu
 *
 */
const HeaderHamburgerMenuContainer = styled.div`
  display: inline-block;
  position: relative;
  .hamburger-button {
    color: ${({ isimpersonated }) => (isimpersonated ? '#db2828 !important' : 'inherit')};
  }
`
const HeaderHamburgerMenu = () => {
  const { userIsImpersonated } = React.useContext(UserContext)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const handleMenuToggle = (menuStatus) => {
    setIsMenuOpen(menuStatus)
  }

  return (
    <HeaderHamburgerMenuContainer isimpersonated={userIsImpersonated}>
      <HeaderButton
        icon="bars"
        isActive={isMenuOpen}
        className="hamburger-button"
        onClick={(e) => handleMenuToggle(true)}
      />
      <Menu isOpen={isMenuOpen} onStatusChange={(status) => handleMenuToggle(status)} />
    </HeaderHamburgerMenuContainer>
  )
}

export default HeaderHamburgerMenu
