import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import differenceInSeconds from 'date-fns/differenceInSeconds'

/**
 * @description Santander warehouse code.
 */
const SANTANDER = 'sa'

/**
 * @description Vitoria warehouse code.
 */
const VITORIA = 'vi'

/**
 * @description Madrid warehouse code.
 */
const MADRID = 'ma'

/**
 * @description Palencia warehouse code.
 */
const PALENCIA = 'pa'

/**
 * @description Asturias warehouse code.
 */
const ASTURIAS = 'as'

/**
 * @description Holidays in the different warehouses. It affects the
 * calculation of the delivery time.
 */
const HOLIDAYS_BACKUP = {
  all: ['20190101', '20190106', '20190419', '20190501', '20190419'],
  [SANTANDER]: ['20190422'],
  [VITORIA]: ['20190422'],
  [MADRID]: ['20190502'],
  [PALENCIA]: ['20190418'],
  [ASTURIAS]: ['20190502'],
}

/* ******************** */
/* WAREHOUSE PRIORITIES */
/* ******************** */

/**
 * @description Control if some warehouses have strict exclusivity for products / stock.
 * At this point, only MADRID warehouses has this logic implemented.
 */
export const EXCLUSIVITY = 1

const DEFAULT_WAREHOUSE_PRIORITY = !EXCLUSIVITY ? [SANTANDER, ASTURIAS, VITORIA, PALENCIA, MADRID] : [SANTANDER, ASTURIAS, VITORIA, PALENCIA]

/*const WAREHOUSES_STOCK_FILTER = {
  [MADRID]: EXCLUSIVITY ? [MADRID] : DEFAULT_WAREHOUSE_PRIORITY,
  [SANTANDER]: DEFAULT_WAREHOUSE_PRIORITY,
  [VITORIA]:  DEFAULT_WAREHOUSE_PRIORITY,
  [PALENCIA]:  DEFAULT_WAREHOUSE_PRIORITY,
  [ASTURIAS]: DEFAULT_WAREHOUSE_PRIORITY,

}*/


const ASTURIAS_WAREHOUSE_PRIORITY = {
  default: [ASTURIAS, ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => v !== ASTURIAS)],
  main_first: [
    SANTANDER,
    ASTURIAS,
    ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => ![SANTANDER, ASTURIAS].includes(v)),
  ],
}

const MADRID_WAREHOUSE_PRIORITY = EXCLUSIVITY ? 
{
  default: [MADRID],
  main_first: [
    MADRID,
  ],
}
: {
  default: [MADRID, ...(EXCLUSIVITY ? [] : DEFAULT_WAREHOUSE_PRIORITY.filter((v) => v !== MADRID) ) ],
  main_first: [
    MADRID,
    (EXCLUSIVITY ? '': SANTANDER),
    
    ...(EXCLUSIVITY ? '': DEFAULT_WAREHOUSE_PRIORITY.filter((v) => ![SANTANDER, MADRID].includes(v)) ),
  ],
}
/*const MADRID_WAREHOUSE_PRIORITY = {
  default: [MADRID],
  main_first: [
    MADRID,
    
  ],
}*/

const PALENCIA_WAREHOUSE_PRIORITY = {
  default: [PALENCIA, ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => v !== PALENCIA)],
  main_first: [
    SANTANDER,
    PALENCIA,
    ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => ![SANTANDER, PALENCIA].includes(v)),
  ],
}

const VITORIA_WAREHOUSE_PRIORITY = {
  default: [VITORIA, ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => v !== VITORIA)],
  main_first: [
    SANTANDER,
    VITORIA,
    ...DEFAULT_WAREHOUSE_PRIORITY.filter((v) => ![SANTANDER, VITORIA].includes(v)),
  ],
}

/* *************** */
/* ASTURIAS CONFIG */
/* *************** */

const ASTURIAS_WAREHOUSE = {
  default: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 0 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 0 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
  ],
  saturday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 0 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 2 },
    },
  ],
  sunday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 1 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 1 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
  ],
  holiday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 1 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 1 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: ASTURIAS_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 1 },
    },
  ],
}

/* ************* */
/* MADRID CONFIG */
/* ************* */
const MADRID_WAREHOUSE = {
  default: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 0, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  friday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 0, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  saturday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  sunday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  holiday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 1, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: MADRID_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
}

/* *************** */
/* PALENCIA CONFIG */
/* *************** */

const PALENCIA_WAREHOUSE = {
  default: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 0, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 1, [PALENCIA]: 0, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 3 },
    },
  ],
  saturday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 0, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 3 },
    },
  ],
  sunday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
  ],
  holiday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 1, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: PALENCIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 3, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 3 },
    },
  ],
}

/* **************** */
/* SANTANDER CONFIG */
/* **************** */

const SANTANDER_WAREHOUSE = {
  default: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 0, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 0, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 2, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
  ],
  saturday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 0, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 2, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  sunday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
  ],
  holiday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: DEFAULT_WAREHOUSE_PRIORITY,
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 1, [PALENCIA]: 1, [ASTURIAS]: 1 },
    },
  ],
}

/* ************** */
/* VITORIA CONFIG */
/* ************** */

const VITORIA_WAREHOUSE = {
  default: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 0, [VITORIA]: 0, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 0, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  saturday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 0, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 3, [VITORIA]: 3, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  sunday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
  holiday: [
    {
      until: 50399, // 13:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 62999, // 17:29:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['default'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 79199, // 21:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 2, [PALENCIA]: 2, [ASTURIAS]: 2 },
    },
    {
      until: 86399, // 23:59:59
      warehouse_priority: VITORIA_WAREHOUSE_PRIORITY['main_first'],
      delivery_days: { [SANTANDER]: 1, [VITORIA]: 1, [MADRID]: 3, [PALENCIA]: 3, [ASTURIAS]: 3 },
    },
  ],
}

/* ******************** */
/* ALL CONFIGS TOGETHER */
/* ******************** */

export const DELIVERYDAYS_BACKUP = {
  [ASTURIAS]: ASTURIAS_WAREHOUSE,
  [MADRID]: MADRID_WAREHOUSE,
  [PALENCIA]: PALENCIA_WAREHOUSE,
  [SANTANDER]: SANTANDER_WAREHOUSE,
  [VITORIA]: VITORIA_WAREHOUSE,
}

/**
 * If you are going to modify the code of this function, you have to make sure that the tests continue to pass successfully!!
 * -> `npm run test`
 *
 * @description Calculate shipping parameters.
 * @see Tests In src/\_\_tests\_\_/calculateShipments.*.test.js
 * @param {Number} quantity Requested quantity.
 * @param {String} warehouseCode Customer warehouse code.
 * @param {Object} stocks Quantity in stock of the product in the different warehouses.
 * @param {Array} forceWarehouseKeys Warehouse keys to return, empty if regular flow.
 * @returns {Array} [shipmentStatus, shipments, unitsPending]
 *   - shipmentStatus: It can have the values: `full` (Order fully served), `half` (Partial Stock) or `empty` (Out of stock).
 *   - shipments: The shipments that will be made from the different warehouses.
 *   - unitsPending: Units pending shipment.
 */
const calculateShipments = (quantity = 0, warehouseCode, stocks, forceWarehouseKeys = [], DELIVERYDAYS = [], HOLIDAYS= HOLIDAYS_BACKUP) => {
  
  /* Return unless any of the warehouses has stock. */
  if (!stocks || !Object.values(stocks).some((stock) => stock > 0)) {
    return ['empty', [], quantity]
  }
  
  if(DELIVERYDAYS.length === 0) DELIVERYDAYS = DELIVERYDAYS_BACKUP;
  if(HOLIDAYS.length === 0) HOLIDAYS = HOLIDAYS_BACKUP;
  
  const startOfToday = startOfDay(new Date())

  /**
   * @description Check if current day is in the holidays list or not.
   */
  const isHoliday = [...HOLIDAYS.all, ...HOLIDAYS[warehouseCode]].includes(
    format(startOfToday, 'yyyyMMdd')
  )

  /**
   * @description Name of the current day of the week.
   * @var {String}
   * @example 'thursday'
   */
  const todayWeekday = new Date().toLocaleString('en', { weekday: 'long' }).toLowerCase()

  /**
   * @description Difference in seconds from the beginning of the day until
   * now. Useful to calculate the time interval to be taken as a reference.
   * @var {Number}
   */
  const secondsOfToday = differenceInSeconds(new Date(), startOfToday)

  /**
   * @var {Object}
   * @example
   * {
   *   until: 86399, // 23:59:59
   *   warehouse_priority: ['sa', 'as', 'vi', 'pa', 'ma'],
   *   delivery_days: { sa: 1, vi: 2, ma: 2, pa: 1, as: 2 },
   * }
   */
  const timeInterval = (
    isHoliday && DELIVERYDAYS[warehouseCode].hasOwnProperty('holiday')
      ? DELIVERYDAYS[warehouseCode]['holiday']
      : DELIVERYDAYS[warehouseCode].hasOwnProperty(todayWeekday)
      ? DELIVERYDAYS[warehouseCode][todayWeekday]
      : DELIVERYDAYS[warehouseCode]['default']
  )
    .filter((timerange) => timerange.until > secondsOfToday)
    .reduce((reduced, timerange) =>
      timerange.until - secondsOfToday < reduced - secondsOfToday ? timerange.until : reduced
    )

  // Product shipments initialization.
  let shipmentStatus = 'empty'
  let shipments = []
  let unitsPending = quantity

  timeInterval.warehouse_priority.forEach((warehouseKey) => {
    if (forceWarehouseKeys.length > 0 && !forceWarehouseKeys.includes(warehouseKey)) {
      return
    }

    // Check if the order is complete, if not, go ahead.
    if (shipmentStatus === 'full') {
      return
    }

    const warehouseStock = Number(stocks[warehouseKey])

    // If the warehouse doesn´t have stock, go to the next one.
    if (warehouseStock === 0) {
      return
    }

    // Add a partial or total shipment, depending on the stock available.
    shipments.push({
      warehouse: warehouseKey,
      quantity: warehouseStock >= unitsPending ? unitsPending : warehouseStock,
      days: timeInterval.delivery_days[warehouseKey],
    })

    if (warehouseStock >= unitsPending) {
      shipmentStatus = 'full'
      unitsPending = 0

      return
    }

    shipmentStatus = 'half'
    unitsPending -= warehouseStock
  })

  return [shipmentStatus, shipments, unitsPending]
}

export default calculateShipments
