import React from 'react'
import { es } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import styled from 'styled-components'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

import { formatCurrency } from '../../utils'
import { APIBASEURL } from '../../globals'

const NMONTHS = 6
const NINVOICES = 3

const LastInvoicesWidgetContainer = styled.div`
  & .last-invoices__empty {
    text-align: center;
    font-style: italic;
    padding: 2rem;
  }
  & .last-invoices__invoice {
    color: #000;
    padding: 0.8rem 1.4rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #f8f8f8;
    }
  }
  & .last-invoices__invoice__number {
    font-size: 1em;
    color: #555;
  }
  & .last-invoices__invoice__date {
    font-size: 1.1em;
    font-weight: bold;
  }
  & .last-invoices__invoice__total {
    font-size: 1.1em;
    line-height: 1;
  }
`

const LastInvoicesWidget = () => {
  const today = new Date()
  const [data, status] = useFetch(
    '/invoices',
    `?page=1&from=${format(subMonths(today, NMONTHS), 'yyyyMMdd')}&to=${format(today, 'yyyyMMdd')}&`
  )

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error')
    return <WidgetError description="No se pueden cargar las últimas facturas" />

  const lastInvoices = data.data ? data.data.slice(0, NINVOICES) : []

  return (
    <LastInvoicesWidgetContainer>
      {lastInvoices.length > 0 ? (
        lastInvoices.map(({ numero_factura, fecha_factura, importe }) => (
          <a
            key={numero_factura}
            href={`${APIBASEURL}/invoices/${numero_factura}/pdf`}
            className="last-invoices__invoice"
          >
            <div>
              <div className="last-invoices__invoice__number">{parseInt(numero_factura, 10)}</div>
              <div className="last-invoices__invoice__date">
                {format(parseISO(fecha_factura), "d' de 'MMMM", {
                  locale: es,
                })}
              </div>
            </div>
            <div className="last-invoices__invoice__total">{formatCurrency(importe)}</div>
          </a>
        ))
      ) : (
        <div className="last-invoices__empty">No hay facturas</div>
      )}
    </LastInvoicesWidgetContainer>
  )
}

export default LastInvoicesWidget
