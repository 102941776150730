import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import ProductCartInputs from './CartInputs'
import ProductPrice from './Price'
import DeliveryTime from '../DeliveryTime'

import { useDiscountRange, useStock } from '../../hooks'

import { triTernaryConcatenation } from '../../utils'
import { COLOR, OFFERCOLOR, TRANSFERCOLOR, IVATYPES, APIBASEURL } from '../../globals'
import { useEffect } from 'react'

const ProductHeaderSearchResultStyled = styled.div`
  font-size: 1rem;
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 1rem 1.6rem;
  background: ${(props) =>
    props.transfer ? TRANSFERCOLOR.light : props.offer ? OFFERCOLOR.light : '#fff'};

  ${({ transfer, offer, onlinePromotionsItem }) =>
    (transfer || offer || onlinePromotionsItem) &&
    css`
      border-left: ${triTernaryConcatenation(
        transfer,
        TRANSFERCOLOR.dark,
        offer,
        OFFERCOLOR.dark,
        onlinePromotionsItem?.category_color,
        onlinePromotionsItem?.category_color,
        OFFERCOLOR.dark
      )};
      border-right: ${triTernaryConcatenation(
        transfer,
        TRANSFERCOLOR.dark,
        offer,
        OFFERCOLOR.dark,
        onlinePromotionsItem?.category_color,
        onlinePromotionsItem?.category_color,
        OFFERCOLOR.dark
      )};
      padding: 1rem 1.1rem;
    `}
  &:last-child {
    margin-bottom: 3rem;
  }
  & .result__flag {
    position: absolute;
    top: 0.6rem;
    right: 1rem;
    cursor: ${({ onlinePromotionsItem }) => (onlinePromotionsItem ? 'pointer' : 'default')};
    &::before {
      content: ${(props) =>
        triTernaryConcatenation(
          props.transfer,
          "'EN BOLSA'",
          props.onlinePromotionsItem?.category_label,
          `'${props.onlinePromotionsItem?.category_label?.toUpperCase()}'`,
          props.offer,
          "'OFERTA'",
          "'OFERTA'"
        )};
      background: ${(props) =>
        triTernaryConcatenation(
          props.transfer,
          TRANSFERCOLOR.dark,
          props.onlinePromotionsItem?.category_color,
          props.onlinePromotionsItem?.category_color,
          props.offer,
          OFFERCOLOR.dark,
          OFFERCOLOR.dark
        )};
      color: #fff;
      font-size: 0.7em;
      line-height: 1;
      border-radius: 10rem;
      padding: 0.2em 0.8em;
      transform: none;
    }
  }
  & .result__photo {
    display: none;
  }
  & .result__info {
    & .result__info__codes {
      font-size: 0.8em;
      line-height: 1;
      margin-bottom: 0.25rem;
    }
    & .result__info__description {
      color: ${COLOR};
      line-height: 1;
      margin-bottom: 0.15rem;
      &:hover {
        text-decoration: underline;
      }
    }
    & .result__info__lab {
      font-size: 0.8em;
      color: #777;
      line-height: 1;
      margin-bottom: 0.4rem;
    }
    & .result__info__delivery-time {
      display: block;
      font-size: 0.85rem;
    }
  }
  & .result__prices {
    font-size: 0.9rem;
    margin-top: 0.6rem;
    display: flex;
    justify-content: space-around;
    background: #ddd;
    padding: 0.4rem;
    border-radius: 0.4rem;
  }
  & .result__cart-inputs {
    margin-top: 1rem;
    justify-content: space-between;
  }
  @media (min-width: 1025px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.3rem 2rem 0.3rem 5px;
    ${({ transfer, offer, onlinePromotionsItem }) =>
      (transfer || offer || onlinePromotionsItem) &&
      css`
        padding-right: 1.6rem;
        border-left: none;
      `}
    & .result__flag {
      width: 18px;
      position: absolute;
      top: 0rem;
      left: 0rem;
      bottom: 0rem;
      background: ${(props) =>
        triTernaryConcatenation(
          props.transfer,
          TRANSFERCOLOR.dark,
          props.offer,
          OFFERCOLOR.dark,
          props.onlinePromotionsItem?.category_color,
          props.onlinePromotionsItem?.category_color,
          OFFERCOLOR.dark
        )};
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      cursor: ${({ onlinePromotionsItem }) => (onlinePromotionsItem ? 'pointer' : 'default')};
      &::before {
        content: ${(props) =>
          triTernaryConcatenation(
            props.transfer,
            "'EN BOLSA'",
            props.offer,
            "'OFERTA'",
            props.onlinePromotionsItem?.category_label,
            `'${props.onlinePromotionsItem?.category_label?.toUpperCase()}'`,
            "'OFERTA'"
          )};
        font-size: 0.75em;
        white-space: nowrap;
        border-radius: none;
        padding: 0;
        transform: rotate(-90deg);
        width: 11ch;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
    & .result__photo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100px;
      & img {
        max-width: 100px;
        max-height: 45px;
      }
    }
    & .result__info {
      flex: 1 10 auto;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      & .result__info__delivery-time {
        display: inline-block;
        padding: 0.2rem 0.6rem 0.2rem 0.2rem;
      }
    }
    & .result__prices {
      background: transparent;
      padding: 0;
      margin-top: 0;
      justify-content: flex-start;
      width: 250px;
      & > div {
        margin-right: 1.6rem;
      }
    }
    & .result__cart-inputs {
      justify-content: flex-start;
      margin-top: 0;
    }
  }
  @media (min-width: 1281px) {
    & .result__prices {
      font-size: 1rem;
    }
  }
`
const ProductHeaderSearchResult = ({
  displayPrices,
  onlinePromotionsItem = undefined,
  onSelectProduct,
  id,
  oferta,
  descuento,
  transfer,
  item_id,
  sap_codigo,
  sap_descripcion,
  laboratorio,
  ean11,
  iva,
  pvf,
  pvl,
  pvp,
  estado,
  estupe,
  afterVoucherValidation,
  ...restProps
}) => {
  const [quantity, setQuantity] = useState(0)

  const [stocks,status,shipments] = useStock(sap_codigo,quantity);
  const [stockStatus, setStockStatus] = useState('')
  const [, selectedDiscount] = useDiscountRange(
    transfer ? `0~${descuento}-% /` : descuento,
    quantity
  )
  const productHasDiscount = selectedDiscount && selectedDiscount.value > 0

  useEffect(()=>{
    setStockStatus(status)
  },[status])

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity || 0)
  }

  const handleItemClick = () => {
    onSelectProduct()
  }

  return (
    <ProductHeaderSearchResultStyled
      transfer={transfer}
      offer={oferta}
      onlinePromotionsItem={onlinePromotionsItem}
      {...restProps}
    >
      {(transfer || oferta || onlinePromotionsItem) && (
        <div
          className="result__flag"
          role="button"
          onClick={(event) => {
            if (transfer || oferta) {
              return
            }

            event.stopPropagation()
            navigate(`/online/${onlinePromotionsItem.promotion_id}`)
          }}
        />
      )}

      <div className="result__photo">
        <Image
          src={`${APIBASEURL}/items/${parseInt(
            sap_codigo,
            10
          )}/image?size=thumbnails&type=TH&w=100&h=100&fit=crop`}
          alt={sap_descripcion}
        />
      </div>
      <div className="result__info">
        <div className="result__info__codes">
          {parseInt(sap_codigo, 10)}
          {ean11 ? ` | EAN ${ean11}` : ''}
        </div>
        <Link
          to={`/productos/${sap_codigo}`}
          onClick={handleItemClick}
          className="result__info__description"
        >
          {sap_descripcion}
        </Link>
        <div className="result__info__lab">{laboratorio}</div>
        <DeliveryTime
          quantity={quantity}
          sapCode={sap_codigo}
          sapDescription={sap_descripcion}
          productStatus={estado}
          className="result__info__delivery-time"
          stocks={stocks}
          stockStatus={stockStatus}
          shipments={shipments}     
        />
      </div>
      {displayPrices && (
        <div className="result__prices">
          {iva && (
            <ProductPrice
              label="IVA"
              price={IVATYPES[iva.toString()] ? IVATYPES[iva.toString()].sort : 0}
              noCurrencyFormat
            />
          )}
          {/* <ProductPrice
            label="PRECIO"
            price={pvl}
            {...(productHasDiscount
              ? {
                  discount: selectedDiscount.value,
                }
              : {})}
          /> */}

          <ProductPrice
            label="PVL"
            price={pvl}
            {...(productHasDiscount
              ? {
                  discount: selectedDiscount.value,
                }
              : {})}
          />

          {/* {parseInt(sap_codigo) < 600000 
              ? 
              <ProductPrice
                label="PVL"
                price={null}
                noCurrencyFormat
              />
              : 
              <ProductPrice
                label="PVL"
                price={pvl}
                {...(productHasDiscount
                  ? {
                      discount: selectedDiscount.value,
                    }
                  : {})}
              />
            } */}

          <ProductPrice
            label="PVF"
            price={pvf}
            {...(productHasDiscount
              ? {
                  discount: selectedDiscount.value,
                }
              : {})}
          />
          <ProductPrice label="PVP" price={pvp} />
        </div>
      )}
      <ProductCartInputs
        className="result__cart-inputs"
        horizontal
        productSapCode={sap_codigo}
        onQuantityChange={handleQuantityChange}
        afterVoucherValidation={afterVoucherValidation}
        isNarcotic={estupe}
        stockStatus={stockStatus}
      />
    </ProductHeaderSearchResultStyled>
  )
}

ProductHeaderSearchResult.propTypes = {
  displayPrices: PropTypes.bool,
  onSelectProduct: PropTypes.func,
  afterVoucherValidation: PropTypes.func,
}

ProductHeaderSearchResult.defaultProps = {
  displayPrices: true,
  onSelectProduct: () => null,
  afterVoucherValidation: () => true,
}

export default ProductHeaderSearchResult
