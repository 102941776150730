import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import setHours from 'date-fns/setHours'
import isBefore from 'date-fns/isBefore'
import max from 'date-fns/max'
import firebase from 'firebase/app'
import 'firebase/database'

import indexedDb from '../../indexedDb'
import { APIBASEURL } from '../../globals'

export const ProductsContext = createContext()

export const ProductsConsumer = ProductsContext.Consumer

export const ProductsProvider = ({ children }) => {
  const [productsStatus, setProductsStatus] = useState('init')
  const [offersStatus, setOffersStatus] = useState('init')
  const [transfersStatus, setTransfersStatus] = useState('init')
  const [onlinePromotionsStatus, setOnlinePromotionsStatus] = useState('init')

  const [products, setProducts] = useState([])
  const [offers, setOffers] = useState([])
  const [transfers, setTransfers] = useState([])
  const [onlinePromotions, setOnlinePromotions] = useState([])
  const [displayPrices, setDisplayPrices] = useState(
    sessionStorage.getItem('cenfarte.displayPrices') === null
      ? true
      : sessionStorage.getItem('cenfarte.displayPrices') === '1'
      ? true
      : false
  )
  const [displayStock, setDisplayStock] = useState(
    sessionStorage.getItem('cenfarte.displayStock') === null
      ? false
      : sessionStorage.getItem('cenfarte.displayStock') === '1'
      ? true
      : false
  )

  // Donwloads products list from the server.
  const downloadProducts = useCallback(() => {
    setProductsStatus('downloading')
    axios({
      method: 'GET',
      url: `${APIBASEURL}/items`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: { data, errors } }) => {
        saveProductsToIndexedDbAndState(data)
      })
      .catch((error) => {
        loadProductsFromIndexedDbToState()
        console.error(error)
      })
  }, [])

  // Donwloads product offers list from the server.
  const downloadOffers = useCallback(() => {
    setOffersStatus('downloading')
    axios({
      method: 'GET',
      url: `${APIBASEURL}/offers`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: { data, errors } }) => {
        saveOffersToIndexedDbAndState(data)
      })
      .catch((error) => {
        loadOffersFromIndexedDbToState()
        console.error(error)
      })
  }, [])

  // Donwloads product transfers list from the server.
  const downloadTransfers = useCallback(() => {
    setTransfersStatus('downloading')
    axios({
      method: 'GET',
      url: `${APIBASEURL}/transfers/deferred/items`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: { data, errors } }) => {
        saveTransfersToIndexedDbAndState(data)
      })
      .catch((error) => {
        loadTransfersFromIndexedDbToState()
        console.error(error)
      })
  }, [])

  // Donwloads product transfers list from the server.
  const downloadOnlinePromotionsItems = useCallback(() => {
    setOnlinePromotionsStatus('downloading')
    axios({
      method: 'GET',
      url: `${APIBASEURL}/restricted/promotions/featured-items`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: { data, errors } }) => {
        saveOnlinePromotionsItemsToState(data)
      })
      .catch((error) => {
        // loadTransfersFromIndexedDbToState()
        console.error(error)
      })
  }, [])

  // Remove database
  const removeDatabase = useCallback(() => {
    indexedDb
      .delete()
      .then(() => {
        console.log('indexedDb database successfully deleted')
        localStorage.setItem(
          'cenfarte.databaseCacheLastUpdate',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        )
        window.location.reload(true)
      })
      .catch((error) => {
        console.error('Could not delete database')
      })
  }, [])

  useEffect(() => {
    // If display prices flag is not in browser's cache (sessionStorage),
    // create it with the default value.
    if (!sessionStorage.getItem('cenfarte.displayPrices')) {
      sessionStorage.setItem('cenfarte.displayPrices', '1')
    }

    if (!sessionStorage.getItem('cenfarte.displayStock')) {
      sessionStorage.setItem('cenfarte.displayStock', '0')
    }

    downloadOnlinePromotionsItems()

    checkDatabase()
      .then((response) => {
        checkItem('products', downloadProducts, loadProductsFromIndexedDbToState)
        checkItem('offers', downloadOffers, loadOffersFromIndexedDbToState)
        checkItem('transfers', downloadTransfers, loadTransfersFromIndexedDbToState)
      })
      .catch((error) => {
        removeDatabase()
      })
  }, [
    downloadProducts,
    downloadOffers,
    downloadTransfers,
    downloadOnlinePromotionsItems,
    removeDatabase,
  ])

  const checkDatabase = () => {
    return new Promise((resolve, reject) => {
      const browserDatabaseLastUpdate = localStorage.getItem(`cenfarte.databaseCacheLastUpdate`)
      const databaseDateRef = firebase.database().ref(`caches/database`)

      // Set a timeout to handle the case that the last cache reading doesn't get a response.
      const timeoutId = setTimeout(
        (databaseDateRef) => {
          const message =
            'TIMEOUT: No se ha podido acceder a Firebase para leer la fecha de la última actualización de la base de datos'
          console.error(message)
          Sentry.captureException(message)
          databaseDateRef.off()
          resolve(message)
        },
        10000,
        databaseDateRef
      )

      databaseDateRef
        .once('value')
        .then((snapshot) => {
          clearTimeout(timeoutId)

          const firebaseDatabaseLastUpdate = snapshot.val()

          if (
            !browserDatabaseLastUpdate ||
            isBefore(parseISO(browserDatabaseLastUpdate), parseISO(firebaseDatabaseLastUpdate))
          ) {
            const message =
              'EXPIRED DATABASE: La base de datos está desactualizada y se va a reiniciar.'
            Sentry.captureException(message)
            reject(message)
          } else {
            resolve('La base de datos está actualizada')
          }
        })
        .catch((error) => {
          console.error(error)
          const message =
            'DATABASE ERROR: No se ha podido acceder a Firebase para leer la fecha de la última actualización de la base de datos'
          Sentry.captureException(message)
          reject(message)
        })
    })
  }

  // Check if items table exists in browser's cache (indexedDb).
  // If not, download items from the server and create the table.
  const checkItem = (itemName, downloadItems, loadFromBrowserCache) => {
    indexedDb[itemName]
      .count()
      .then((nItems) => {
        const dtTodayUpdateCache = setHours(startOfDay(new Date()), 4)
        const browserLastCacheUpdate = localStorage.getItem(`cenfarte.${itemName}CacheLastUpdate`)

        const cacheDateRef = firebase.database().ref(`caches/${itemName}`)

        // Set a timeout to handle the case that the last cache reading doesn't get a response.
        const timeoutId = setTimeout(
          (cacheDateRef) => {
            const message =
              'TIMEOUT: No se ha podido acceder a Firebase para leer la fecha de la última actualización de cache'
            console.error(message)
            Sentry.captureException(message)

            // Cancel firebase event listener (once).
            cacheDateRef.off()

            // Download items list and update browser's cache if current
            // table is empty or if the last cache was updated before the
            // today updating configured date.
            if (
              nItems === 0 ||
              !browserLastCacheUpdate ||
              isBefore(parseISO(browserLastCacheUpdate), dtTodayUpdateCache)
            )
              downloadItems()
            else loadFromBrowserCache()
          },
          10000,
          cacheDateRef
        )

        cacheDateRef
          .once('value')
          .then((snapshot) => {
            clearTimeout(timeoutId)

            // Get the latest date between firebase and today update.
            const firebaseLastCacheUpdate = snapshot.val()

            const dtLastCacheUpdate = max([
              ...(firebaseLastCacheUpdate ? [parseISO(firebaseLastCacheUpdate)] : []),
              dtTodayUpdateCache,
            ])

            // Download items list and update browser's cache if current
            // table is empty or if the last cache was updated before the
            // last list update or the firebase configured date.
            if (
              nItems === 0 ||
              !browserLastCacheUpdate ||
              isBefore(parseISO(browserLastCacheUpdate), dtLastCacheUpdate)
            )
              downloadItems()
            else loadFromBrowserCache()
          })
          .catch((error) => {
            console.error(error)

            // Download items list and update browser's cache if current
            // table is empty or if the last cache was updated before the
            // today updating configured date.
            if (
              nItems === 0 ||
              !browserLastCacheUpdate ||
              isBefore(parseISO(browserLastCacheUpdate), dtTodayUpdateCache)
            )
              downloadItems()
            else loadFromBrowserCache()
          })
      })
      .catch(() => {
        downloadItems()
      })
  }

  // Save the products list array to browser's cache (indexedDb)
  // and to state.
  const saveProductsToIndexedDbAndState = (products) => {
    setProductsStatus('saving')
    indexedDb
      .transaction('rw', indexedDb.products, () => {
        indexedDb.products.clear()
        indexedDb.products.bulkAdd(products).then((lastKey) => {
          console.log(`${products.length} products added to indexedDb`)
          setProducts(products)
          localStorage.setItem(
            'cenfarte.productsCacheLastUpdate',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
          )
          setProductsStatus('ok')
        })
      })
      .catch((error) => {
        console.error(error)
        setProductsStatus('error')
      })
  }

  // Save the offers list array to browser's cache (indexedDb)
  // and to state.
  const saveOffersToIndexedDbAndState = (offers) => {
    setOffersStatus('saving')
    indexedDb
      .transaction('rw', indexedDb.offers, () => {
        indexedDb.offers.clear()
        indexedDb.offers.bulkAdd(offers).then((lastKey) => {
          console.log(`${offers.length} offers added to indexedDb`)
          // Convert offers array to object and save it to state.
          setOffers(
            offers.reduce((t, offer) => {
              t[offer.sap_codigo] = offer
              return t
            }, {})
          )
          localStorage.setItem(
            'cenfarte.offersCacheLastUpdate',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
          )
          setOffersStatus('ok')
        })
      })
      .catch((error) => {
        console.error(error)
        setOffersStatus('error')
      })
  }

  // Save the transfers list array to browser's cache (indexedDb)
  // and to state.
  const saveTransfersToIndexedDbAndState = (transfers) => {
    setTransfersStatus('saving')
    indexedDb
      .transaction('rw', indexedDb.transfers, () => {
        indexedDb.transfers.clear()
        indexedDb.transfers.bulkAdd(transfers).then((lastKey) => {
          console.log(`${transfers.length} transfers added to indexedDb`)
          // Convert transfers array to object and save it to state.
          setTransfers(
            transfers.reduce((t, transfer) => {
              t[transfer.sap_codigo] = transfer
              return t
            }, {})
          )
          localStorage.setItem(
            'cenfarte.transfersCacheLastUpdate',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
          )
          setTransfersStatus('ok')
        })
      })
      .catch((error) => {
        console.error(error)
        setTransfersStatus('error')
      })
  }

  // Save the offers list array to browser's cache (indexedDb)
  // and to state.
  const saveOnlinePromotionsItemsToState = (items) => {
    setOnlinePromotionsStatus('saving')
    console.log(`${items.length} promotion items added to state`)
    setOnlinePromotions(items)
    setOnlinePromotionsStatus('ok')

    // indexedDb
    //   .transaction('rw', indexedDb.offers, () => {
    //     indexedDb.offers.clear()
    //     indexedDb.offers.bulkAdd(offers).then((lastKey) => {
    //       console.log(`${offers.length} offers added to indexedDb`)
    //       // Convert offers array to object and save it to state.
    //       setOffers(
    //         offers.reduce((t, offer) => {
    //           t[offer.sap_codigo] = offer
    //           return t
    //         }, {})
    //       )
    //       localStorage.setItem(
    //         'cenfarte.offersCacheLastUpdate',
    //         format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    //       )
    //       setOnlinePromotionsStatus('ok')
    //     })
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //     setOnlinePromotionsStatus('error')
    //   })
  }

  // Take products list from browser's cache (indexedDb) and
  // load it in state.
  const loadProductsFromIndexedDbToState = () => {
    setProductsStatus('saving')
    indexedDb.products.toArray().then((productsArr) => {
      setProducts(productsArr)
      setProductsStatus('ok')
    })
  }

  // Take offers list from browser's cache (indexedDb) and
  // load it in state.
  const loadOffersFromIndexedDbToState = () => {
    setOffersStatus('saving')
    indexedDb.offers.toArray().then((offersArray) => {
      setOffers(
        offersArray.reduce((t, offer) => {
          t[offer.sap_codigo] = offer
          return t
        }, {})
      )
      setOffersStatus('ok')
    })
  }

  // Take transfers list from browser's cache (indexedDb) and
  // load it in state.
  const loadTransfersFromIndexedDbToState = () => {
    setTransfersStatus('saving')
    indexedDb.transfers.toArray().then((transfersArray) => {
      setTransfers(
        transfersArray.reduce((t, transfer) => {
          t[transfer.sap_codigo] = transfer
          return t
        }, {})
      )
      setTransfersStatus('ok')
    })
  }

  // Set display prices flag in browser's cache (sessionStorage)
  // and in state.
  const setDisplayPricesStatus = useCallback((status) => {
    setDisplayPrices(status)
    sessionStorage.setItem('cenfarte.displayPrices', status ? '1' : '0')
  }, [])

  // Set display stock flag in browser's cache (sessionStorage)
  // and in state.
  const setDisplayStockStatus = useCallback((status) => {
    setDisplayStock(status)
    sessionStorage.setItem('cenfarte.displayStock', status ? '1' : '0')
  }, [])

  const value = useMemo(
    () => ({
      productsStatus,
      offersStatus,
      transfersStatus,
      onlinePromotionsStatus,
      products,
      offers,
      transfers,
      onlinePromotions,
      displayPrices,
      displayStock,
      downloadProducts,
      downloadOffers,
      downloadTransfers,
      downloadOnlinePromotionsItems,
      setDisplayPricesStatus,
      setDisplayStockStatus,
      removeDatabase,
    }),
    [
      productsStatus,
      offersStatus,
      transfersStatus,
      onlinePromotionsStatus,
      products,
      offers,
      transfers,
      onlinePromotions,
      displayPrices,
      displayStock,
      downloadProducts,
      downloadOffers,
      downloadTransfers,
      downloadOnlinePromotionsItems,
      setDisplayPricesStatus,
      setDisplayStockStatus,
      removeDatabase,
    ]
  )

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>
}

export function withProductsContext(Component) {
  return function WithProductsContextComponent(props) {
    return (
      <ProductsContext.Consumer>
        {(products) => <Component {...props} products={products} />}
      </ProductsContext.Consumer>
    )
  }
}
