import React, { useContext } from 'react'
import { Link } from '@reach/router'
import { Icon, Button, Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

import { UserContext } from './Context/User'
import HeaderButton from './HeaderButton'

import { COLOR } from '../globals'

const DropdownContainer = styled.div`
  display: inline-block;
  border-left: 1px solid #cccccc;
  padding-left: 0.8em;
  margin-left: 0.4em;
`
const User = styled.span`
  user-select: none;
  .menu-icon {
    margin-right: 0rem !important;
  }
  span {
    display: none;
  }
  @media (min-width: 1280px) {
    span {
      display: inline;
      margin-left: 0.6rem;
    }
  }
`

/**
 * Component: HeaderMenu
 * Menu at the right of the header.
 */
const HeaderMenu = React.memo(({ ...restProps }) => {
  const {
    userIsImpersonated,
    userName,
    userCif,
    userAddress,
    userCity,
    userProvince,
    userPostalCode,
    logoutUser,
    userIsAdmin,
    userIsManager,
  } = useContext(UserContext)

  const handleLogOut = () => {
    logoutUser()
  }

  return (
    <DropdownContainer>
      <Dropdown
        trigger={
          <User isimpersonated={userIsImpersonated} {...restProps}>
            <HeaderButton icon="building" className="menu-icon" />
            <span {...(userIsImpersonated ? { style: { color: '#db2828' } } : {})}>{userName}</span>
          </User>
        }
        pointing="top right"
      >
        <Dropdown.Menu direction="left" style={{ minWidth: '170px', zIndex: '2000' }}>
          <Dropdown.Header
            style={{
              textTransform: 'none',
              fontSize: '0.9em',
              fontWeight: 'normal',
            }}
          >
            <div {...(userIsImpersonated ? { style: { color: '#db2828' } } : {})}>
              <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>{userName}</div>
              <div style={{ opacity: '0.7' }}>{userCif}</div>
              <div
                style={{
                  fontSize: '0.9rem',
                  marginTop: '0.6rem',
                  display: 'flex',
                }}
              >
                <Icon name="point" style={{ margin: '0.1rem 0.5rem 0 0' }} />
                <div>
                  <div>{userAddress}</div>
                  <div>
                    {userPostalCode} {userCity}
                  </div>
                  <div>{userProvince}</div>
                </div>
              </div>
            </div>
          </Dropdown.Header>
          <Dropdown.Divider />
          {(userIsAdmin() || userIsManager()) && (
            <>
              <Dropdown.Item as={Link} icon="users" text="Mis usuarios" to="/usuarios" />
              <Dropdown.Item as={Link} icon="lock" text="Cambiar contraseña" to="/farmacia" />
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item>
            <Button
              size="small"
              fluid
              style={{ color: '#fff', background: COLOR }}
              icon="log out"
              content="Desconectar"
              onClick={handleLogOut}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownContainer>
  )
})

export default HeaderMenu
