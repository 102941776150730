import React from 'react'
import { Link } from '@reach/router'
import DOMPurify from 'dompurify'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import WidgetError from './Error'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

import { COLOR } from '../../globals'

const GenericWidgetContainer = styled.div`
  background: ${({ bkgColor }) => bkgColor || COLOR};
  border-radius: inherit;
  font-size: 1.1rem;
  & a {
    display: block;
    transition: opacity 0.2s ease;
    opacity: 0.85;
    color: ${({ textColor }) => textColor || '#ffffff'};
  }
  & a:hover {
    opacity: 1;
    color: ${({ textColor }) => textColor || '#ffffff'};
  }
  & .widget-content__image {
    width: 100%;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  & .widget-content__text {
    padding: 1.6rem;
  }
  & .widget-content__text__title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0.8rem;
  }
  & .widget-content__text__description {
    p {
      line-height: 1.3;
    }
  }
  @media (min-width: 769px) {
    font-size: 1.2rem;
  }
`

const GenericWidget = React.memo(({ widgetId, ...restProps }) => {
  const [data, status] = useFetch(`/restricted/widgets/${widgetId}`)

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error')
    return <WidgetError description="No se pueden cargar los datos del widget" />
  else if (!data || !data.data) return <WidgetError description="El widget no devuelve datos" />

  const { image, title, description, background_color, text_color, link_type, link } = data.data.data

  const PromotionLink = !link_type || !link ? 'div' : link_type === 'internal-route' ? Link : 'a'

  return (
    <GenericWidgetContainer bkgColor={background_color} textColor={text_color} {...restProps}>
      <PromotionLink
        {...(link_type === 'internal-route' && link
          ? { to: DOMPurify.sanitize(link) }
          : (link_type === 'external-link' ||
              link_type === 'unnefar' ||
              link_type === 'unnefar-with-url') &&
            link
          ? {
              href: link,
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {})}
      >
        {image && <Image src={image} className="widget-content__image" />}
        {(title || description) && (
          <div className="widget-content__text">
            <div className="widget-content__text__title">{title}</div>
            <div
              className="widget-content__text__description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description),
              }}
            />
          </div>
        )}
      </PromotionLink>
    </GenericWidgetContainer>
  )
})

export default GenericWidget
