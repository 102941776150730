import React, { useContext, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { UserContext } from '../components/Context'
import GenericWidget from '../components/Widgets/Generic'
import OnlinePromotionWidget from '../components/Widgets/OnlinePromotion'
import GlobalAlert from '../components/Widgets/GlobalAlert'
import WidgetContainer from '../components/Widgets/Container'
import LastOrdersWidget from '../components/Widgets/LastOrders'
import LastInvoicesWidget from '../components/Widgets/LastInvoices'
import NewsWidget from '../components/Widgets/News'
import EventsWidget from '../components/Widgets/Events'
import ExternalPromotionWidget from '../components/Widgets/ExternalPromotion'
import DocumentsWidget from '../components/Widgets/Documents'
import HealthAlertsWidget from '../components/Widgets/HealthAlerts'

import { useMedia } from '../hooks'

const widgets = [
  {
    key: 'lOW',
    title: 'Últimos pedidos',
    Component: LastOrdersWidget,
    compContainer: document.createElement('div'),
    isPrivateArea: true,
  },
  {
    key: 'lIW',
    title: 'Últimas facturas',
    Component: LastInvoicesWidget,
    compContainer: document.createElement('div'),
    isPrivateArea: true,
  },
  {
    key: 'pW',
    title: 'Ofertas destacadas',
    widgetId: 2,
    Component: GenericWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'pW',
    title: 'Promociones online',
    widgetId: 2,
    Component: OnlinePromotionWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'orW',
    widgetId: 3,
    Component: GenericWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'fd',
    widgetId: 5,
    Component: GenericWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'pSW',
    widgetId: 1,
    Component: GenericWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'nW',
    Component: NewsWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'edW',
    //title: 'ExpresFarma Digital',
    widgetId: 4,
    Component: GenericWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'ePW1',
    Component: ExternalPromotionWidget,
    params: { widget: 1 },
    compContainer: document.createElement('div'),
  },
  {},
  {},
  {
    key: 'ePW2',
    Component: ExternalPromotionWidget,
    params: { widget: 2 },
    compContainer: document.createElement('div'),
  },
  {},
  {},
  {
    key: 'ePW3',
    Component: ExternalPromotionWidget,
    params: { widget: 3 },
    compContainer: document.createElement('div'),
  },
  {},
  {
    key: 'eW',
    title: 'Agenda de eventos',
    Component: EventsWidget,
    compContainer: document.createElement('div'),
  },
  {},
  {
    key: 'hAW',
    title: 'Alertas sanitarias',
    Component: HealthAlertsWidget,
    compContainer: document.createElement('div'),
  },
  {
    key: 'dW',
    title: 'Documentos',
    Component: DocumentsWidget,
    compContainer: document.createElement('div'),
  },
]

const Dashboard = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin: -0.5rem;
  & .dashboard__column {
    flex: 1 1 auto;
    max-width: ${(props) => (props.ncolumns ? `${100 / props.ncolumns}%` : '')};
    padding: 0.5rem;
    & > div {
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 769px) {
    font-size: 1rem;
  }
`

// This is the component that will be rendered, wrapping
// a portal to the final component.
const CompContainer = ({ element }) => {
  const elementRef = useRef(null)

  useEffect(() => {
    elementRef.current.appendChild(element)
  }, [element])

  return <div ref={elementRef} />
}

const ScreensDashboard = () => {
  const { userIsAdmin, userIsManager } = useContext(UserContext)

  // Get the number of columns depending on the screen width.
  const nColumns = useMedia(['(min-width: 1281px)', '(min-width: 769px)'], [3, 2], 1)

  // Distribute widgets in the corresponding columns.
  const widgetsByColumn = widgets.reduce(
    (t, widgetData, i) => {
      if (widgetData.isPrivateArea === true && !userIsAdmin() && !userIsManager()) return t
      t[i % nColumns].push(widgetData)
      return t
    },
    [...Array(nColumns)].map(() => [])
  )

  return (
    <>
      {/* Create dashboard with the calculated number of columns and render
      the widget container components that will insert the DOM nodes that
      render the real widgets through the portals. */}
      <GlobalAlert />
      <Dashboard ncolumns={nColumns}>
        {widgetsByColumn.map((column, i) => {
          return (
            <div key={`col-${i}`} className="dashboard__column">
              {column.map(({ key, compContainer }) => {
                return compContainer ? <CompContainer key={key} element={compContainer} /> : null
              })}
            </div>
          )
        })}
      </Dashboard>
      {/* Now we render the widgets inside of its containers using portals */}
      <div>
        {widgets.reduce(
          (t, { widgetId, title, Component, params, compContainer, isPrivateArea }) => {
            if (isPrivateArea === true && !userIsAdmin() && !userIsManager()) return t

            t.push(
              compContainer
                ? ReactDOM.createPortal(
                    <WidgetContainer {...(title && { title })}>
                      <Component {...(widgetId ? { widgetId } : {})} {...(params ? params : {})} />
                    </WidgetContainer>,
                    compContainer
                  )
                : null
            )

            return t
          },
          []
        )}
      </div>
    </>
  )
}

export default ScreensDashboard
