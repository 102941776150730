import React from 'react'
import { Link } from '@reach/router'
import MediaQuery from 'react-responsive'
import { Table, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

import { COLOR } from '../../globals'

const OrderViewContainer = styled.div`
  font-size: 0.9rem;
  & a,
  & a:hover {
    color: ${COLOR};
  }
  & .order-view__table {
    & tbody {
      display: block;
      max-height: 50vh !important;
      overflow-y: scroll !important;
    }
    & thead,
    & tbody tr {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
    & thead tr th:last-child {
      width: 1em !important;
      padding: 0 !important;
    }
  }
  @media (min-width: 769px) {
    font-size: 1rem;
  }
`

const OrderView = ({ orderNumber, ...restProps }) => {
  const [data, status] = useFetch(`/orders/${orderNumber}`)

  if (status === 'loading') return <GenericLoader style={{ margin: '2rem 0' }} />
  else if (status === 'error')
    return (
      <div style={{ color: '#db2828', margin: '2rem 0', textAlign: 'center' }}>
        ERROR: No se pueden cargar los datos del pedido
      </div>
    )

  if (data.errors && data.errors.length > 0) {
    return (
      <div style={{ color: '#db2828' }}>
        <div>
          <strong>ERROR:</strong>
        </div>
        <ul>
          {data.errors.map(error => (
            <li key={error}>{error}.</li>
          ))}
        </ul>
      </div>
    )
  }

  const NoData = ({ nCols }) => (
    <Table.Row>
      <Table.Cell textAlign="center" colSpan={nCols}>
        <i>No hay productos</i>
      </Table.Cell>
    </Table.Row>
  )

  const productsList = data.data

  return (
    <OrderViewContainer>
      <Table unstackable striped attached className="order-view__table">
        <MediaQuery maxWidth={768}>
          <>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Producto</Table.HeaderCell>
                <Table.HeaderCell>Cantidades</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!productsList || productsList.length === 0 ? (
                <NoData nCols={2} />
              ) : (
                productsList.map((product, i) => (
                  <Table.Row key={`${product.codigo_articulo}${i}`}>
                    <Table.Cell>
                      <div>{product.codigo_articulo}</div>
                      <div>
                        <Link to={`/productos/${product.codigo_articulo}`}>
                          <strong>{product.nombre_articulo}</strong>
                        </Link>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {product.cantidad_pedida}/{product.cantidad_servida}
                      </div>
                      {product.motivo_rechazo && (
                        <div style={{ marginTop: '0.2rem' }}>
                          <Label color="red" content={product.motivo_rechazo} />
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Producto</Table.HeaderCell>
                <Table.HeaderCell>Cant. pedida</Table.HeaderCell>
                <Table.HeaderCell>Cant. servida</Table.HeaderCell>
                <Table.HeaderCell>Rechazo</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body
              style={{
                display: 'block',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {!productsList || productsList.length === 0 ? (
                <NoData nCols={5} />
              ) : (
                productsList.map((product, i) => (
                  <Table.Row key={`${product.codigo_articulo}${i}`}>
                    <Table.Cell>
                      <div>{product.codigo_articulo}</div>
                      <div>
                        <Link to={`/productos/${product.codigo_articulo}`}>
                          <strong>{product.nombre_articulo}</strong>
                        </Link>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{product.cantidad_pedida}</Table.Cell>
                    <Table.Cell>{product.cantidad_servida}</Table.Cell>
                    <Table.Cell>
                      {product.motivo_rechazo && (
                        <Label color="red" content={product.motivo_rechazo} />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </>
        </MediaQuery>
      </Table>
    </OrderViewContainer>
  )
}

export default OrderView
