import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Form, Message, Icon, Button, Segment, Popup } from 'semantic-ui-react'

import { COLOR } from '../globals'

/**
 * Shows a form with login and password inputs, makes an API request,
 * handle error and returns when success. Also allow password reminder form.
 *
 * @class LoginForm
 * @extends {PureComponent}
 */
class LoginForm extends PureComponent {
  static propTypes = {
    urlLogin: PropTypes.string.isRequired,
    urlLoginReminder: PropTypes.string,
    apiRequestOptions: PropTypes.object,
    loginPlaceholder: PropTypes.string,
    loginUrlVar: PropTypes.string,
    pwdPlaceholder: PropTypes.string,
    pwdUrlVar: PropTypes.string,
    privateUrlVar: PropTypes.string,
    buttonProps: PropTypes.object,
    errorMessageLogin: PropTypes.string,
    errorMessageLoginReminder: PropTypes.string,
    loginResponseValidation: PropTypes.func,
    handleLoginSuccess: PropTypes.func,
    handleLoginError: PropTypes.func,
    loginReminderResponseValidation: PropTypes.func,
    successMessageLoginReminder: PropTypes.string,
    passwordResetToken: PropTypes.string,
    passwordResetUrl: PropTypes.string,
    passwordResetResponseValidation: PropTypes.func,
    onPasswordResetEnd: PropTypes.func,
    errorMessagePasswordReset: PropTypes.string,
  }
  static defaultProps = {
    apiRequestOptions: {},
    loginPlaceholder: 'Usuario',
    loginUrlVar: 'login',
    pwdPlaceholder: 'Contraseña',
    pwdUrlVar: 'password',
    privateUrlVar: 'remember',
    buttonProps: {},
    errorMessageLogin: 'El usuario o password es incorrecto. Por favor, inténtalo de nuevo',
    errorMessageLoginReminder:
      'Se ha producido un error. Por favor, inténtalo de nuevo en unos minutos.',
    loginResponseValidation: data => null,
    handleLoginSuccess: data => null,
    handleLoginError: data => null,
    loginReminderResponseValidation: data => null,
    successMessageLoginReminder:
      'Te hemos enviado un correo electrónico con los pasos a seguir. ¡Muchas gracias!',
    passwordResetResponseValidation: data => null,
    onPasswordResetEnd: () => null,
    errorMessagePasswordReset:
      'Se ha producido un error al resetear la contraseña. Por favor, inténtalo de nuevo en unos minutos.',
  }

  state = {
    status: 'relax',
    screen: this.props.passwordResetToken ? 'reset-password-form' : 'login',
    login: '',
    password: '',
    email: '',
    privateSystem: false,
    loginReminderResultMessage: null,
    newPassword: '',
    newPasswordConfirm: '',
    passwordResetResultMessage: null,
  }

  handleFieldChange = (e, { name, value, checked }) => {
    const realValue = checked !== undefined ? checked : value
    this.setState({ [name]: realValue })
  }

  handleDismissErrorMessage = () => {
    this.resetState()
  }

  handleSubmitLogin = () => {
    const { urlLogin, apiRequestOptions, loginUrlVar, pwdUrlVar, privateUrlVar } = this.props
    const { login, password, privateSystem } = this.state
    this.setState({ status: 'authenticating' })
    this.postLogin(
      urlLogin,
      apiRequestOptions,
      loginUrlVar,
      pwdUrlVar,
      privateUrlVar,
      login,
      password,
      privateSystem
    )
  }

  handleForgotPassword = () => {
    this.setScreen('remind-password-form')
  }

  handleSubmitReminder = () => {
    const { urlLoginReminder, apiRequestOptions } = this.props
    const { login, email } = this.state
    this.setState({ status: 'sending-reminder' })
    this.postLoginReminder(urlLoginReminder, apiRequestOptions, login, email)
  }

  handleBackToLogin = () => {
    this.setScreen('login')
  }

  handlePasswordReset = () => {
    const { passwordResetUrl, apiRequestOptions, passwordResetToken } = this.props
    const { newPassword, newPasswordConfirm } = this.state
    this.setState({ status: 'sending-password-reset' })
    this.postPasswordReset(
      passwordResetUrl,
      apiRequestOptions,
      passwordResetToken,
      newPassword,
      newPasswordConfirm
    )
  }

  handlePasswordResetEnd = () => {
    this.props.onPasswordResetEnd()
  }

  resetState = () => {
    this.setState({
      status: 'relax',
      login: '',
      password: '',
      email: '',
      privateSystem: false,
    })
  }

  setScreen = screen => {
    this.setState({ screen, status: 'relax' })
  }

  postLogin = (
    urlLogin,
    apiRequestOptions,
    loginUrlVar,
    pwdUrlVar,
    privateUrlVar,
    login,
    password,
    privateSystem
  ) => {
    const formData = new FormData()
    formData.append(loginUrlVar, login.trim())
    formData.append(pwdUrlVar, password.trim())
    formData.append(privateUrlVar, privateSystem)

    axios({
      method: 'post',
      url: urlLogin,
      data: formData,
      ...apiRequestOptions,
    })
      .then(response => {
        if (this.props.loginResponseValidation(response)) {
          this.setState({ status: 'success-login' })
          this.props.handleLoginSuccess(response)
        } else {
          this.setState({ status: 'error-login' })
          this.props.handleLoginError(new Error('Credentials validation has failed.'))
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error-login' })
        this.props.handleLoginError(error)
      })
  }

  postLoginReminder = (urlLoginReminder, apiRequestOptions, login, email) => {
    axios({
      method: 'post',
      url: urlLoginReminder,
      data: {
        username: login.trim(),
        email: email.trim(),
      },
      ...apiRequestOptions,
    })
      .then(response => {
        if (this.props.loginReminderResponseValidation(response)) {
          this.setState({ status: 'success-login-reminder', loginReminderResultMessage: null })
          this.setScreen('remind-password-success')
        } else {
          this.setState({
            status: 'error-login-reminder',
            ...(response.data.errors && Object.keys(response.data.errors).length > 0
              ? { loginReminderResultMessage: response.data.message }
              : {}),
          })
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error-login-reminder' })
      })
  }

  postPasswordReset = (
    passwordResetUrl,
    apiRequestOptions,
    passwordResetToken,
    newPassword,
    newPasswordConfirm
  ) => {
    axios({
      method: 'post',
      url: passwordResetUrl,
      data: {
        token: passwordResetToken,
        password: newPassword.trim(),
        password_confirm: newPasswordConfirm.trim(),
      },
      ...apiRequestOptions,
    })
      .then(response => {
        if (this.props.passwordResetResponseValidation(response)) {
          this.setState({ status: 'success-password-reset', passwordResetResultMessage: null })
          this.setScreen('reset-password-success')
        } else {
          this.setState({
            status: 'error-password-reset',
            ...(response.data.errors && Object.keys(response.data.errors).length > 0
              ? { passwordResetResultMessage: response.data.message }
              : {}),
          })
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ status: 'error-password-reset' })
      })
  }

  render() {
    const {
      urlLogin,
      urlLoginReminder,
      apiRequestOptions,
      loginPlaceholder,
      loginUrlVar,
      pwdPlaceholder,
      pwdUrlVar,
      privateUrlVar,
      buttonProps: { style: buttonStyles, ...restButtonProps },
      errorMessageLogin,
      errorMessageLoginReminder,
      loginResponseValidation,
      handleLoginSuccess,
      handleLoginError,
      loginReminderResponseValidation,
      successMessageLoginReminder,
      passwordResetToken,
      passwordResetUrl,
      passwordResetResponseValidation,
      onPasswordResetEnd,
      errorMessagePasswordReset,
      ...rest
    } = this.props
    const {
      status,
      screen,
      login,
      password,
      email,
      privateSystem,
      loginReminderResultMessage,
      newPassword,
      newPasswordConfirm,
      passwordResetResultMessage,
    } = this.state

    return (
      <div id="login-form-component">
        {screen === 'login' && (
          <Form error={status === 'error-login'} {...rest}>
            <Form.Input
              size="large"
              icon="user"
              iconPosition="left"
              placeholder={loginPlaceholder}
              name="login"
              value={login}
              onChange={this.handleFieldChange}
            />
            <Form.Input
              size="large"
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder={pwdPlaceholder}
              name="password"
              value={password}
              onChange={this.handleFieldChange}
            />
            <Message
              error
              header="ERROR"
              content={errorMessageLogin}
              onDismiss={this.handleDismissErrorMessage}
            />
            <div style={{ marginTop: '1.8em', display: 'flex' }}>
              <Form.Checkbox
                label="Estoy en la farmacia"
                name="privateSystem"
                checked={privateSystem}
                onChange={this.handleFieldChange}
              />
              <Popup
                trigger={
                  <Icon
                    name="help circle"
                    style={{
                      fontSize: '1.3em',
                      marginLeft: '0.6em',
                      marginTop: '0.05em',
                      color: COLOR,
                    }}
                  />
                }
                on={['hover', 'click']}
                header="¿Es privado este dispositivo?"
                content="Si estás en la farmacia y por lo tanto este dispositivo es privado, no desconectaremos automáticamente tu sesión al cabo de un tiempo de inactividad."
              />
            </div>
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: '1.2em', ...buttonStyles }}
              content="Entrar en el área de cliente"
              loading={status === 'authenticating'}
              onClick={this.handleSubmitLogin}
              {...restButtonProps}
            />
            {urlLoginReminder && (
              <div style={{ textAlign: 'center' }}>
                <span
                  style={{ cursor: 'pointer', color: '#4183c4' }}
                  onClick={this.handleForgotPassword}
                >
                  ¿Has olvidado tu contraseña?
                </span>
              </div>
            )}
          </Form>
        )}
        {screen === 'remind-password-form' && (
          <Form error={status === 'error-login-reminder'} {...rest}>
            <Form.Input
              size="large"
              icon="user"
              iconPosition="left"
              placeholder={loginPlaceholder}
              name="login"
              value={login}
              onChange={this.handleFieldChange}
            />
            <Form.Input
              size="large"
              icon="mail"
              iconPosition="left"
              placeholder="Email"
              name="email"
              value={email}
              onChange={this.handleFieldChange}
            />
            <Message
              error
              header="ERROR"
              content={loginReminderResultMessage || errorMessageLoginReminder}
              onDismiss={this.handleDismissErrorMessage}
            />
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: '2em', ...buttonStyles }}
              content="Recordar contraseña"
              loading={status === 'sending-reminder'}
              onClick={this.handleSubmitReminder}
              {...restButtonProps}
            />
            <div style={{ textAlign: 'center' }}>
              <span
                style={{ cursor: 'pointer', color: '#4183c4' }}
                onClick={this.handleBackToLogin}
              >
                ¿Ya tienes tu contraseña?
              </span>
            </div>
          </Form>
        )}
        {screen === 'remind-password-success' && (
          <Fragment>
            <Segment>
              <div style={{ textAlign: 'center' }}>
                <Icon name="check" size="huge" color="green" />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '1em',
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                }}
              >
                ¡Proceso completado!
              </div>
              <div style={{ textAlign: 'center', marginTop: '0.6em' }}>
                {successMessageLoginReminder}
              </div>
            </Segment>
            <Button
              fluid
              size="large"
              style={{ marginTop: '1.6em', ...buttonStyles }}
              content="Volver a Login"
              onClick={this.handleBackToLogin}
              {...restButtonProps}
            />
          </Fragment>
        )}
        {screen === 'reset-password-form' && (
          <Form error={status === 'error-password-reset'} autoComplete="off" {...rest}>
            <h3 style={{ margin: '-1rem 0 0.6rem 0' }}>Reset de contraseña</h3>
            <div style={{ marginBottom: '1.4rem', fontSize: '0.95em' }}>
              <p>Por favor, introduce la nueva contraseña y pulsa el botón para enviar.</p>
              <p>
                <i>La contraseña tiene que tener una longitud mínima de 8 caracteres.</i>
              </p>
            </div>
            <Form.Input
              size="large"
              type="password"
              autoComplete="new-password"
              icon="lock"
              iconPosition="left"
              placeholder={'Nueva contraseña'}
              name="newPassword"
              value={newPassword}
              onChange={this.handleFieldChange}
            />
            <Form.Input
              size="large"
              type="password"
              autoComplete="new-password"
              icon="lock"
              iconPosition="left"
              placeholder={'Repetir nueva contraseña'}
              name="newPasswordConfirm"
              value={newPasswordConfirm}
              onChange={this.handleFieldChange}
            />
            <Message
              error
              header="ERROR"
              content={passwordResetResultMessage || errorMessagePasswordReset}
              onDismiss={this.handleDismissErrorMessage}
            />
            <Form.Button
              fluid
              size="large"
              style={{ marginTop: '2em', ...buttonStyles }}
              content="Resetear contraseña"
              disabled={
                newPassword.length < 8 ||
                newPasswordConfirm.length < 8 ||
                newPassword !== newPasswordConfirm
              }
              loading={status === 'sending-password-reset'}
              onClick={this.handlePasswordReset}
              {...restButtonProps}
            />
          </Form>
        )}
        {screen === 'reset-password-success' && (
          <Fragment>
            <Segment>
              <div style={{ textAlign: 'center' }}>
                <Icon name="check" size="huge" color="green" />
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '1em',
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                }}
              >
                Tu contraseña ha sido reestablecida. ¡Muchas gracias!
              </div>
            </Segment>
            <Button
              fluid
              size="large"
              style={{ marginTop: '1.6em', ...buttonStyles }}
              content="Entrar en el área de cliente"
              onClick={this.handlePasswordResetEnd}
              {...restButtonProps}
            />
          </Fragment>
        )}
      </div>
    )
  }
}

export default LoginForm
