import React, { createContext, useState, useEffect, useMemo, useCallback, useContext } from 'react'
import axios from 'axios'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import setHours from 'date-fns/setHours'
import isBefore from 'date-fns/isBefore'

import { UserContext } from './User'
import indexedDb from '../../indexedDb'
import { APIBASEURL } from '../../globals'

export const PharmaciesContext = createContext()

export const PharmaciesConsumer = PharmaciesContext.Consumer

export const PharmaciesProvider = ({ children }) => {
  const [isDownloadingPharmacies, setIsDownloadingPharmacies] = useState(false)
  const [pharmacies, setPharmacies] = useState([])
  const { userIsAdmin } = useContext(UserContext)

  // Check if items table exists in browser's cache (indexedDb).
  // If not, download items from the server and create the table.
  const checkItem = (itemName, downloadItems, loadFromBrowserCache) => {
    indexedDb[itemName]
      .count()
      .then((nItems) => {
        // Save current datetime as last cache update.
        const cacheLastUpdate = localStorage.getItem(`cenfarte.${itemName}CacheLastUpdate`)

        // Download offers list and update browser's cache if current
        // table is empty or if the last cache was updated before the
        // last offers list update.
        if (
          nItems === 0 ||
          !cacheLastUpdate ||
          isBefore(parseISO(cacheLastUpdate), setHours(startOfDay(new Date()), 2))
        )
          downloadItems()
        else loadFromBrowserCache()
      })
      .catch(() => {
        downloadItems()
      })
  }

  // Save the pharmacies list array to browser's cache (indexedDb)
  // and to state.
  const savePharmaciesToIndexedDbAndState = (pharmacies) => {
    indexedDb
      .transaction('rw', indexedDb.pharmacies, () => {
        indexedDb.pharmacies.clear()
        indexedDb.pharmacies.bulkAdd(pharmacies).then((lastKey) => {
          console.log(`${pharmacies.length} users added to indexedDb`)
          setPharmacies(pharmacies)
          localStorage.setItem(
            'cenfarte.pharmaciesCacheLastUpdate',
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
          )
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // Take pharmacies list from browser's cache (indexedDb) and
  // load it in state.
  const loadPharmaciesFromIndexedDbToState = () => {
    indexedDb.pharmacies.toArray().then((pharmaciesArr) => {
      pharmaciesArr.sort((a, b) => a.kunnr - b.kunnr)
      setPharmacies(pharmaciesArr)
    })
  }

  // Donwloads pharmacies list from the server.
  const downloadPharmacies = useCallback(() => {
    if (!userIsAdmin()) return
    
    setIsDownloadingPharmacies(true)
    axios({
      method: 'GET',
      url: `${APIBASEURL}/users/expanded-list`,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({ data: { data } }) => {
        setIsDownloadingPharmacies(false)
        savePharmaciesToIndexedDbAndState(data)
      })
      .catch((error) => {
        setIsDownloadingPharmacies(false)
        loadPharmaciesFromIndexedDbToState()
        console.error(error)
      })
  }, [userIsAdmin])

  useEffect(() => {
    checkItem('pharmacies', downloadPharmacies, loadPharmaciesFromIndexedDbToState)
  }, [downloadPharmacies])

  const value = useMemo(
    () => ({
      isDownloadingPharmacies,
      pharmacies,
      downloadPharmacies,
    }),
    [isDownloadingPharmacies, pharmacies, downloadPharmacies]
  )

  return <PharmaciesContext.Provider value={value}>{children}</PharmaciesContext.Provider>
}

export function withPharmaciesContext(Component) {
  return function WithPharmaciesContextComponent(props) {
    return (
      <PharmaciesContext.Consumer>
        {(pharmacies) => <Component {...props} pharmacies={pharmacies} />}
      </PharmaciesContext.Consumer>
    )
  }
}
