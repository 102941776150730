import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const InfoMessageContainer = styled.div`
  margin: 1.6rem 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
  & .info-message__icon {
    font-size: 7em;
    color: #ccc;
  }
  & .info-message__title {
    margin-top: 1.4rem;
    font-size: 2.2em;
    font-weight: bold;
  }
  & .info-message__description {
    margin: 1.4rem 1.6rem 0 1.6rem;
    color: #999;
    & > div {
      margin-top: 0.8rem;
    }
  }
  @media (min-width: 769px) {
    margin-top: 3rem;
    & .info-message__icon {
      font-size: 12em;
    }
    & .info-message__title {
      font-size: 3.6em;
    }
    & .info-message__description {
      font-size: 1.2em;
    }
  }
`

const InfoMessage = ({ icon, title, description, ...restProps }) => {
  return (
    <InfoMessageContainer {...restProps}>
      <Icon name={icon} className="info-message__icon" />
      <div className="info-message__title">{title}</div>
      <div className="info-message__description">{description}</div>
    </InfoMessageContainer>
  )
}

export default InfoMessage
