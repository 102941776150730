import React from 'react'
import { Image, Icon } from 'semantic-ui-react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import slugify from 'slugify'

import WidgetError from './Error'
import { GenericLoader } from '../UI'
import { useFetch } from '../../hooks'

import { COLOR } from '../../globals'

const NewsWidgetContainer = styled.div`
  border-radius: inherit;
  & a,
  & a:hover {
    color: #000;
  }
  & .news__last-new {
    display: block;
    &:hover {
      opacity: 0.8;
    }
  }
  & .news__last-new__image {
    width: 100%;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  & .news__last-new__text {
    padding: 1.6rem;
  }
  & .news__last-new__text__title {
    font-weight: bold;
    font-size: 1.4em;
    color: ${COLOR};
    margin-bottom: 0.8rem;
  }
  & .news__link {
    display: block;
    border-top: 1px solid #eee;
    text-align: right;
    padding: 1rem 1.6rem;
    font-size: 1.1em;
    &:hover {
      background: #e5e5e5;
    }
  }
`

const NewsWidget = () => {
  const [data, status] = useFetch('/restricted/news')

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error') return <WidgetError description="No se pueden cargar las noticias" />

  const { image, title, description, id } = data.data[0]

  return (
    <NewsWidgetContainer>
      <Link to={`/noticias/${id}/${slugify(title, { lower: true })}`}>
        <Image src={image} className="news__last-new__image" />
        <div className="news__last-new__text">
          <div className="news__last-new__text__title">{title}</div>
          <div>{description}</div>
        </div>
      </Link>
      <Link to="/noticias" className="news__link">
        <Icon name="newspaper" style={{ color: '#888', marginRight: '0.6rem' }} />
        Ver más noticias privadas
      </Link>
    </NewsWidgetContainer>
  )
}

export default NewsWidget
