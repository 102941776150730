import { useContext, useEffect } from 'react'
import parseISO from 'date-fns/parseISO'
import getTime from 'date-fns/getTime'
import firebase from 'firebase/app'
import 'firebase/database'

import { ProductsContext } from './Context'

const CachesManager = () => {
  const {
    downloadProducts,
    downloadOffers,
    downloadTransfers,
    downloadOnlinePromotionsItems,
    removeDatabase,
  } = useContext(ProductsContext)

  useEffect(() => {
    const cachesRef = firebase.database().ref('caches')

    const checkForCacheChanges = (cacheType, cacheVal) => {
      const dtNewCacheLastUpdate = parseISO(typeof cacheVal === 'string' ? cacheVal : cacheVal.date)
      // Read the browser cache to obtain the date and time of the last update
      // for the received data type.
      const currentCacheDatetime = localStorage.getItem(`cenfarte.${cacheType}CacheLastUpdate`)
      const dtCurrentCacheLastUpdate = currentCacheDatetime ? parseISO(currentCacheDatetime) : null

      // If we don't have the cache stored o If the received date and time is after the cached one,
      // relaunch the corresponding data type items download.
      if (
        !dtCurrentCacheLastUpdate ||
        getTime(dtNewCacheLastUpdate) > getTime(dtCurrentCacheLastUpdate)
      ) {
        if (cacheType === 'products') {
          downloadProducts()
          downloadOnlinePromotionsItems()
        } else if (cacheType === 'offers') {
          downloadOffers()
        } else if (cacheType === 'transfers') {
          downloadTransfers()
        } else if (cacheType === 'database') {
          removeDatabase()
        }
      }
    }

    //Check for cache updates just once, when component is mounted. The component mount will be executed
    //just in the first load of the application.
    cachesRef.once('value', (snapshot) => {
      const { database, ...cachesList } = snapshot.val()
      Object.entries(cachesList).forEach(([cacheType, cacheVal]) => {
        checkForCacheChanges(cacheType, cacheVal)
      })
    })

    // Set a listener to firebase to received cache updates notifications.
    cachesRef.on('child_changed', (snapshot) => {
      const cacheType = snapshot.key
      const cacheVal = snapshot.val()
      checkForCacheChanges(cacheType, cacheVal)
    })

    return () => {
      cachesRef.off()
    }
  }, [downloadProducts, downloadOffers, downloadTransfers, removeDatabase,downloadOnlinePromotionsItems])

  return null
}

export default CachesManager
