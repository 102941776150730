import { useMemo, useState } from 'react'
import { getDiscountsRanges } from '../utils'

export const useDiscountRange = (discounts, quantity) => {
  const [lastDiscount, setLastDiscount] = useState(0)

  const discountRanges = useMemo(() => getDiscountsRanges(discounts), [discounts])

  if (!discounts) return [[], null]

  const selectedRangeIndex = discountRanges.findIndex(
    (range, i) =>
      quantity >= range.min && (!discountRanges[i + 1] || quantity < discountRanges[i + 1].min)
  )
  const selectedRangeDiscount =
    selectedRangeIndex >= 0 ? discountRanges[selectedRangeIndex].discount : 0

  if (selectedRangeDiscount !== lastDiscount) {
    setLastDiscount(selectedRangeDiscount)
    return [discountRanges, { index: selectedRangeIndex, value: selectedRangeDiscount }]
  }
  return [discountRanges, { index: selectedRangeIndex, value: lastDiscount }]
}
