import React, { useContext, useState, useEffect, useCallback } from 'react'
import DOMPurify from 'dompurify'
import { parseISO, format, startOfDay, isAfter, isBefore } from 'date-fns'
import { Message } from 'semantic-ui-react'
import styled from 'styled-components'

import { ApiContext } from '../Context'

import { useInterval } from '../../hooks'

const UPDATEINTERVAL = 120000

const GlobalAlertMessage = styled(Message)`
  margin-bottom: 1.6rem;
`

const GlobalAlertWidget = () => {
  const { getRequest } = useContext(ApiContext)
  const [alerts, setAlerts] = useState([])
  const [dismissedAlerts, setDismissedAlerts] = useState({})

  const getAlerts = useCallback(() => {
    getRequest('/restricted/alerts')
      .then(({ data }) => {
        setAlerts(data.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [getRequest])

  useEffect(() => {
    setDismissedAlerts(JSON.parse(localStorage.getItem('cenfarte.dismissedGlobalAlerts')))
    getAlerts()
  }, [getAlerts])

  useInterval(() => {
    getAlerts()
  }, UPDATEINTERVAL)

  const handleDismissAlert = alertId => {
    const newDismissedAlerts = {
      ...dismissedAlerts,
      [alertId.toString()]: format(new Date(), 'yyyy-MM-dd'),
    }
    setDismissedAlerts(newDismissedAlerts)
    localStorage.setItem('cenfarte.dismissedGlobalAlerts', JSON.stringify(newDismissedAlerts))
  }

  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map(({ id, title, content, type, start_date }) => {
      if (
        isBefore(startOfDay(new Date()), startOfDay(parseISO(start_date))) ||
        (dismissedAlerts &&
          dismissedAlerts[id.toString()] &&
          !isAfter(
            startOfDay(parseISO(start_date)),
            startOfDay(parseISO(dismissedAlerts[id.toString()]))
          ))
      )
        return null

      return (
        <GlobalAlertMessage
          key={id}
          info={type === 'info'}
          warning={type === 'warning'}
          negative={type === 'negative'}
          positive={type === 'positive'}
          header={title}
          content={
            <div
              style={{ marginTop: '0.2rem' }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
            />
          }
          onDismiss={() => handleDismissAlert(id)}
        />
      )
    })
  )
}

export default GlobalAlertWidget
