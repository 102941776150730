import React, { createContext, useState, useMemo, useCallback } from 'react'
import axios from 'axios'

import { APIBASEURL } from '../../globals'

export const TokenContext = createContext()

export const TokenProvider = ({ children }) => {
  const [token, setUserToken] = useState('')

  const setToken = useCallback(token => {
    setUserToken(token)
  }, [])

  const sendPinToGetToken = useCallback(pin => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: `${APIBASEURL}/token`,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: { pin },
      })
        .then(response => {
          const { authorized, token } = response.data
          if (authorized && token) {
            setUserToken(token)
            resolve(response)
          } else {
            setUserToken('')

            reject(new Error('Token generation error'))
          }
        })
        .catch(error => {
          setUserToken('')
          reject(error)
        })
    })
  }, [])

  const value = useMemo(
    () => ({
      token,
      sendPinToGetToken,
      setToken,
    }),
    [token, sendPinToGetToken, setToken]
  )

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
}

export function withTokenContext(Component) {
  return function WithTokenContextComponent(props) {
    return (
      <TokenContext.Consumer>
        {token => <Component {...props} token={token} />}
      </TokenContext.Consumer>
    )
  }
}
