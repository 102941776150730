import React, { useContext, useEffect } from 'react'
import { Location, Router } from '@reach/router'
import ReactGA from 'react-ga'
import firebase from 'firebase/app'

import {
  PharmaciesProvider,
  ProductsProvider,
  CartProvider,
  UserContext,
  
} from '../components/Context'
import Messages from '../components/Messages'
import Header from '../components/Header'
import Sections from '../components/Sections'
import Footer from '../components/Footer'
import ScreensLogin from './Login'
import GlobalStyle from '../components/styled/GlobalStyle'
import CachesManager from '../components/CachesManager'
import { Container } from '../components/styled'

import { extractUrlParameters } from '../utils'
import { WarehouseContext } from '../components/Context/Warehouse'

// Initialize Firebase
var config =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? {
        apiKey: 'AIzaSyAqCM-17uPSWBAkuSFNpFH7aWtJMdV4GBk',
        authDomain: 'clientes-cenfarte-es.firebaseapp.com',
        databaseURL: 'https://clientes-cenfarte-es.firebaseio.com',
        projectId: 'clientes-cenfarte-es',
        storageBucket: 'clientes-cenfarte-es.appspot.com',
        messagingSenderId: '866827853919',
        appId: '1:866827853919:web:da0dee3a831f15113c7bea',
      }
    : {
        apiKey: 'AIzaSyAE7RSNyO-9TsCvSJh9sNpcdh3CMHjE58w',
        authDomain: 'clientes-cenfarte-es-e6c44.firebaseapp.com',
        databaseURL: 'https://clientes-cenfarte-es-e6c44.firebaseio.com',
        projectId: 'clientes-cenfarte-es-e6c44',
        storageBucket: 'clientes-cenfarte-es-e6c44.appspot.com',
        messagingSenderId: '800125423516',
        appId: '1:800125423516:web:3df16ecc4d3abd200ec259',
      }

firebase.initializeApp(config)

// Initialize Google Analytics.
ReactGA.initialize('UA-142378774-1')

const setGAUrlParameters = (gaHash) => {
  if (!gaHash) return

  const { utm_campaign, utm_medium, utm_source } = extractUrlParameters(gaHash)
  ReactGA.set({
    ...(utm_campaign ? { campaignName: utm_campaign } : {}),
    ...(utm_medium ? { campaignMedium: utm_medium } : {}),
    ...(utm_source ? { campaignSource: utm_source } : {}),
  })
}

/**
 * Component: Root
 * Main component of the application.
 */
const Root = () => {
  const { userStatus, checkUserStatus, userName, userEmail } = useContext(UserContext)
  const { checkWarehouseStatus} = useContext(WarehouseContext)

  // Check if the user is logged in the api.
  useEffect(() => {
    checkUserStatus()
  }, [checkUserStatus])

  useEffect(() => {
      checkWarehouseStatus()
  }, [checkWarehouseStatus])

  // SE DESHABILITA ZENDESK. Revisar para volver a habilitarlo
  /*useEffect(() => {
    if (userStatus === 'logged-in') {
      window.zdonload = setInterval(
        () => {
          if (
            typeof window.zE !== 'undefined' &&
            typeof window.zE.activate !== 'undefined' &&
            userName &&
            userEmail
          ) {
            clearInterval(window.zdonload)

            window.zE(() => {
              window.zE.identify({
                name: userName,
                email: userEmail,
              })
            })
          }
        },
        100,
        null
      )

      if (window.zE) {
        //window.zE('webWidget', 'show')
      } else {
        window.zESettings = {
          webWidget: {
            color: {
              theme: '#409142',
              launcher: '#005102',
              launcherText: '#ffffff',
              button: '#409142',
            },
          },
        }

        const script = document.createElement('script')
        script.id = 'ze-snippet'
        script.src =
          'https://static.zdassets.com/ekr/snippet.js?key=e80463f3-ee93-4f01-80ff-0fa9e4b3cfd4'
        script.async = true
        document.body.appendChild(script)
      }
    } else if (window.zE) {
      window.zE('webWidget', 'hide')
    }
  }, [userStatus, userName, userEmail])*/

  if (userStatus === 'checking') return null
  if (userStatus !== 'logged-in') {
    return (
      <Location>
        {({ location: { hash, search } }) => {
          const urlParams = extractUrlParameters(search)

          // Google Analytics.
          setGAUrlParameters(hash)
          ReactGA.pageview('/login')

          return (
            <>
              <GlobalStyle login />
              <Router style={{ height: '100%' }}>
                <ScreensLogin
                  path="/resetear-password/*"
                  {...(urlParams && urlParams.pwdreset
                    ? { pwdResetToken: urlParams.pwdreset }
                    : {})}
                />
                <ScreensLogin default />
              </Router>
            </>
          )
        }}
      </Location>
    )
  }

  return (
    <Location>
      {({ location: { pathname, hash } }) => {
        // Google Analytics.
        setGAUrlParameters(hash)
        ReactGA.pageview(pathname)

        return (
          <PharmaciesProvider>
            <ProductsProvider>
              <CartProvider>
                <GlobalStyle />
                <CachesManager />
                <Header />
                <main>
                  <Container>
                    <Sections />
                  </Container>
                </main>
                <Footer />
                <Messages />
              </CartProvider>
            </ProductsProvider>
          </PharmaciesProvider>
        )
      }}
    </Location>
  )
}

export default Root
