import React from 'react'
import { Input, Button } from 'semantic-ui-react'
import styled from 'styled-components'

const InputNumberStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;
  & .input-number__button {
    background: #fff !important;
    display: flex !important;
    align-items: center !important;
    &:hover {
      background: #f6f6f6 !important;
    }
    &:active {
      background: #fff !important;
    }
  }
  & input {
    text-align: center !important;
  }
  & .input-number__input {
    min-width: 50px;
    flex: 1 1 auto !important;
    margin: 0 0.4rem !important;
  }
`

export const InputNumber = ({ value, onChange, ...restProps }) => {
  const handleChangeQuantity = e => {
    const value = e.target.value
    if (value === '' || /^\d+$/.test(value)) onChange(parseInt(value || 0, 10))
  }

  const handleQuantityPlus = () => {
    onChange(parseInt(value || 0, 10) + 1)
  }

  const handleQuantityMinus = () => {
    onChange(value > 0 ? parseInt(value || 0, 10) - 1 : 0)
  }

  return (
    <InputNumberStyled {...restProps}>
      <Button
        icon="minus"
        attached="left"
        disabled={value <= 0}
        onClick={handleQuantityMinus}
        className="input-number__button"
      />
      <Input
        value={value}
        onChange={handleChangeQuantity}
        className="input-number__input"
      />
      <Button
        icon="plus"
        attached="right"
        onClick={handleQuantityPlus}
        className="input-number__button"
      />
    </InputNumberStyled>
  )
}
