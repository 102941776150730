import React from 'react'

export const useLeftMenu = onStatusChange => {
  const touchContainerRef = React.useRef(null)
  const touchScrollableContentRef = React.useRef(null)
  const touchStartDataRef = React.useRef(null)
  const touchType = React.useRef(null)

  const handleMenuTouchStart = e => {
    touchStartDataRef.current = getTouchPositionAndTime(e)
  }

  const handleMenuTouchMove = e => {
    // If it's the first movement, check the direction and set the type of movement.
    if (!touchType.current) {
      const { direction } = calculateMovementData(
        touchStartDataRef.current,
        getTouchPositionAndTime(e)
      )
      touchType.current = direction === 'horizontal' ? 'swipe' : 'scroll'
    }

    // If it's a 'swipe', move the menu.
    if (touchType.current === 'swipe') {
      touchScrollableContentRef.current.style.overflowY = 'hidden'
      const distance = e.changedTouches[0].pageX - touchStartDataRef.current.xPos
      if (distance < 0) {
        touchContainerRef.current.style.transition = 'none'
        touchContainerRef.current.style.transform = `translateX(${distance}px)`
      }
    }
  }

  const handleMenuTouchEnd = e => {
    if (touchType.current === 'swipe') {
      const distance = e.changedTouches[0].pageX - touchStartDataRef.current.xPos
      if (distance >= 0) {
        resetTouchEvents()
        return
      }

      // If it's a fast swipe to the left, close the menu.
      const distanceAbs = Math.abs(distance)
      const speed = distanceAbs / (e.timeStamp - touchStartDataRef.current.timeStamp)
      if (
        (distanceAbs > 20 && speed > 0.2) ||
        touchContainerRef.current.getBoundingClientRect().left < -150
      )
        onStatusChange(false)
    }

    resetTouchEvents()
  }

  const getTouchPositionAndTime = event => {
    if (!event) return null

    return {
      xPos: event.changedTouches[0].pageX,
      yPos: event.changedTouches[0].pageY,
      timeStamp: event.timeStamp,
    }
  }

  const calculateMovementData = (startPos, endPos, verticalAngle = 90) => {
    const { xPos: startX, yPos: startY, timeStamp: startTime } = startPos
    const { xPos: endX, yPos: endY, timeStamp: endTime } = endPos
    const halfVerticalAngle = verticalAngle / 2

    const xIncrement = endX - startX
    const xDistance = Math.abs(xIncrement)
    const yIncrement = startY - endY
    const yDistance = Math.abs(yIncrement)

    const distance = Math.sqrt((xDistance ^ 2) + (yDistance ^ 2))
    const time = endTime - startTime
    const speed = distance / time

    const refAngle = Math.atan2(yDistance, xDistance) * (180 / Math.PI)
    const angle =
      xIncrement > 0 && yIncrement > 0
        ? refAngle
        : xIncrement < 0 && yIncrement > 0
        ? 180 - refAngle
        : xIncrement < 0 && yIncrement < 0
        ? 180 + refAngle
        : 360 - refAngle
    const direction =
      (halfVerticalAngle < angle && angle < 90 + halfVerticalAngle) ||
      (270 - halfVerticalAngle < angle && angle < 270 + halfVerticalAngle)
        ? 'vertical'
        : 'horizontal'

    return { xIncrement, xDistance, yIncrement, yDistance, distance, time, speed, angle, direction }
  }

  const resetTouchEvents = () => {
    touchContainerRef.current.style.transition = ''
    touchContainerRef.current.style.transform = ''
    touchScrollableContentRef.current.style.overflowY = ''
    touchStartDataRef.current = null
    touchType.current = null
  }

  return {
    touchContainerRef,
    touchScrollableContentRef,
    handleMenuTouchStart,
    handleMenuTouchMove,
    handleMenuTouchEnd,
    resetTouchEvents,
  }
}
