import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react'

import { UserContext } from './User'

import indexedDb from '../../indexedDb'

export const CartsContext = createContext()

export const CartProvider = ({ children }) => {
  const { userUsername } = useContext(UserContext)
  const [isDownloadingCart, setIsDownloadingCart] = useState(true)
  const [onlineItems, setOnlineItems] = useState({})
  const [items, setItems] = useState({})

  // Take cart from browser's cache (indexedDb) and load it in state.
  const loadCartFromIndexedDbToState = useCallback(
    (userUsernameLocal = null) => {
      setIsDownloadingCart(true)
      setItems({})
      indexedDb.carts
        .where({ userUsername: userUsernameLocal || userUsername })
        .toArray()
        .then((cartItemsArray) => {
          // Convert cart items array to object and save it to state.
          setItems(
            cartItemsArray.reduce((t, cartItem) => {
              t[cartItem.productSapCode] = cartItem
              return t
            }, {})
          )
          setIsDownloadingCart(false)
        })
    },
    [userUsername]
  )

  useEffect(() => {
    loadCartFromIndexedDbToState()
  }, [loadCartFromIndexedDbToState])

  // Empty the browser's cache cart (indexedDb) and update state.
  const empty = useCallback(() => {
    indexedDb.carts
      .where({ userUsername })
      .delete()
      .then(() => {
        setItems({})
      })
  }, [userUsername])

  const emptyOnlineProducts = useCallback(() => {
    setOnlineItems({})
  }, [])

  // Update product info in the browser's cache cart (indexedDb)
  // and update state.
  const setProductData = useCallback(
    (productSapCode, productData) => {
      const newItemData = { productSapCode, ...productData, userUsername }
      indexedDb.carts.put(newItemData).then(() => {
        setItems((prevState) => ({
          ...prevState,
          [productSapCode]: newItemData,
        }))
      })
    },
    [userUsername]
  )

  const addOnlineProducts = useCallback(
    ({ quantity, productSapCode }) => {
      const newItemData = {
        productSapCode: productSapCode,
        quantity: quantity,
        userUsername: userUsername,
      }
      setOnlineItems((prevState) => ({
        ...prevState,
        [productSapCode]: newItemData,
      }))
    },
    [userUsername]
  )

  // Remove one product from the browser's cache cart (indexedDb)
  // and update state.
  const removeProduct = useCallback(
    (productSapCode) => {
      const { [productSapCode]: dummy, ...restItems } = items
      indexedDb.carts
        .where({ userUsername, productSapCode })
        .delete()
        .then(() => {
          setItems(restItems)
        })
    },
    [items, userUsername]
  )

  const value = useMemo(
    () => ({
      isDownloadingCart,
      items,
      empty,
      emptyOnlineProducts,
      setProductData,
      removeProduct,
      loadCartFromIndexedDbToState,
      addOnlineProducts,
      onlineItems,
    }),
    [
      isDownloadingCart,
      items,
      empty,
      emptyOnlineProducts,
      setProductData,
      removeProduct,
      loadCartFromIndexedDbToState,
      addOnlineProducts,
      onlineItems,
    ]
  )

  return <CartsContext.Provider value={value}>{children}</CartsContext.Provider>
}

export function withCartsContext(Component) {
  return function WithCartsContextComponent(props) {
    return (
      <CartsContext.Consumer>
        {(cart) => <Component {...props} cart={cart} />}
      </CartsContext.Consumer>
    )
  }
}
