import React from 'react'
import styled from 'styled-components'

import { UserContext } from './Context/User'

const PharmacyInfoContainer = styled.div`
  font-size: 0.85em;
  margin-top: 0.6em;
  padding: 0.7em 1em;
  border-radius: 0.6em;
  background: ${({ isimpersonated }) => (isimpersonated ? 'rgba(219, 40, 40, 0.3)' : '#3f3f3f')};
  line-height: 1.2;
  color: #bbbbbb;
  .username {
    font-weight: bold;
  }
  .cif {
    margin-top: 0.2em;
    font-size: 0.9em;
  }
`

const PharmacyInfo = () => {
  const { userIsImpersonated, userName, userCif } = React.useContext(UserContext)

  return (
    <PharmacyInfoContainer isimpersonated={userIsImpersonated}>
      <div className="username">{userName}</div>
      <div className="cif">{userCif}</div>
    </PharmacyInfoContainer>
  )
}

export default PharmacyInfo
