import React from 'react'
import { es } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import subMonths from 'date-fns/subMonths'
import styled from 'styled-components'

import WidgetError from './Error'
import OrderModalTrigger from '../Order/ModalTrigger'
import { GenericLoader } from '../UI'

import { useFetch } from '../../hooks'

import { formatCurrency } from '../../utils'
import { APIBASEURL, COLOR } from '../../globals'

const NMONTHS = 1
const NORDERS = 5

const LastOrdersWidgetContainer = styled.div`
  & .last-orders__empty {
    text-align: center;
    font-style: italic;
    padding: 2rem;
  }
  & .last-orders__order {
    color: #000;
    padding: 0.8rem 1.4rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
  }
  & .last-orders__order__number {
    /* border: 1px solid red; */
    /* font-size: 1em;
    color: #555; */
  }
  & .last-orders__order__delivery-note {
    /* border: 1px solid blue; */
    font-size: 1em;
    line-height: 1;
    margin-left: 0.4rem;
    & a {
      display: inline-block;
      color: ${COLOR};
      margin-left: 0.3rem;
      padding-right: 0.3rem;
      border-right: 1px solid #ccc;
    }
    & a:last-of-type {
      padding-right: 0;
      border-right: none;
    }
  }
  & .last-orders__order__status-date {
    font-size: 1em;
    font-weight: bold;
  }
  & .last-orders__order__status-date__status {
    font-size: 0.9em;
    background: #999;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    padding: 0.1rem 0.3rem;
    margin-right: 0.4rem;
  }
  & .last-orders__order__total {
    font-size: 1.1em;
    line-height: 1;
  }
`

const LastOrdersWidget = () => {
  const today = new Date()
  const [data, status] = useFetch(
    '/orders',
    `?page=1&from=${format(subMonths(today, NMONTHS), 'yyyyMMdd')}&to=${format(today, 'yyyyMMdd')}`
  )

  if (status === 'loading') return <GenericLoader style={{ padding: '2.4rem' }} />
  else if (status === 'error')
    return <WidgetError description="No se pueden cargar los últimos pedidos" />

  const lastOrders = data.data ? data.data.slice(0, NORDERS) : []

  return (
    <LastOrdersWidgetContainer>
      {lastOrders.length > 0 ? (
        lastOrders.map(
          ({
            numero_pedido,
            estado,
            fecha_creacion_registro,
            hora_registrada,
            valor_neto_imp_pvl,
            ImpTotal,
            tabla_albaranes,
          }) => (
            <div key={numero_pedido} className="last-orders__order">
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: '0.2rem',
                  }}
                >
                  <div className="last-orders__order__number">
                    {numero_pedido ? (
                      <OrderModalTrigger
                        orderNumber={numero_pedido}
                        triggerProps={{
                          style: { fontSize: '1.1em', lineHeight: '1' },
                        }}
                      />
                    ) : (
                      'SIN CÓDIGO'
                    )}
                  </div>
                  {tabla_albaranes.length > 0 && (
                    <div className="last-orders__order__delivery-note">
                      (Alb.
                      {tabla_albaranes.map(deliveryNote => (
                        <a
                          key={deliveryNote}
                          href={`${APIBASEURL}/delivery-notes/${deliveryNote}/pdf`}
                        >
                          {parseInt(deliveryNote, 10)}
                        </a>
                      ))}
                      )
                    </div>
                  )}
                </div>
                <div className="last-orders__order__status-date">
                  <span className="last-orders__order__status-date__status">{estado}</span>
                  {formatDistance(
                    parseISO(`${fecha_creacion_registro} ${hora_registrada}`),
                    new Date(),
                    { addSuffix: true, locale: es }
                  )}
                </div>
              </div>
              <div className="last-orders__order__total">{formatCurrency(ImpTotal)}</div>
            </div>
          )
        )
      ) : (
        <div className="last-orders__empty">No hay pedidos</div>
      )}
    </LastOrdersWidgetContainer>
  )
}

export default LastOrdersWidget
