import format from 'date-fns/format'

export const formatDatetime = (dtSource, hasTime = true, hasDate = true) => {
  if (!dtSource) return null

  return format(
    dtSource,
    `${hasDate ? 'dd/MM/yyyy' : ''}${hasDate && hasTime ? ' ' : ''}${hasTime ? 'HH:mm' : ''}`
  )
}

export const formatNumber = (number = 0) => {
  return new Intl.NumberFormat('es-ES').format(number)
}

export const formatCurrency = (amount = 0) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export const formatPercent = (percent = 0, base1 = true) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'percent',
  }).format(base1 ? percent : percent / 100)
}

export const objectValues = (obj) => Object.keys(obj).map((v) => obj[v])

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((t, k) => {
    const item = obj[k]
    const element =
      item && typeof item === 'object'
        ? flattenObject(item, `${prefix}${k}/`)
        : [`${prefix}${k}: ${item}`]
    t.push(element)
    return t
  }, [])

export const toSnakeCase = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/\s+/g, '_')

export const detectIE = () => {
  var ua = window.navigator.userAgent

  var msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  var trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  // var edge = ua.indexOf('Edge/')
  // if (edge > 0) {
  //   // Edge (IE 12+) => return version number
  //   return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  // }

  // other browser
  return false
}

export const normalizeString = (str) =>
  !str
    ? ''
    : str
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()

export const getDiscount = (discounts, quantity) => {
  if (!discounts || !quantity) return 0

  const discountRanges = discounts.split('/').reduce((t, v) => {
    if (v) {
      const [min, discount] = v.replace(/[\s-%]/g, '').split('~')
      t.push({
        min: parseInt(min, 10),
        discount: discount ? parseFloat(discount.replace(',', '.')) : 0,
      })
    }
    return t
  }, [])

  const selectedRangeIndex = discountRanges.findIndex(
    (range, i) =>
      quantity >= range.min && (!discountRanges[i + 1] || quantity < discountRanges[i + 1].min)
  )

  return selectedRangeIndex >= 0 ? discountRanges[selectedRangeIndex].discount : 0
}

export const extractUrlParameters = (urlParametersStr) => {
  if (!urlParametersStr) return {}

  const cleanUrlParametersStr = ['#', '?'].includes(urlParametersStr.charAt(0))
    ? urlParametersStr.slice(1)
    : urlParametersStr

  return cleanUrlParametersStr.split('&').reduce((t, urlParameter) => {
    const [name, value] = urlParameter.split('=')
    t[name] = value
    return t
  }, {})
}

/**
 * @description Condición ternaria triple encadenada, por ahorrar código.
 * @param {any} if1 Condición 1.
 * @param {any} return1 A retornar si `if1` es truthy.
 * @param {any} if2 Condición 2.
 * @param {any} return2 A retornar si `if2` es truthy.
 * @param {any} if3 Condición 3.
 * @param {any} return3 A retornar si `if3` es truthy.
 * @param {any} otherwhise A retornar si todo lo demás es falsy.
 * @returns {any}
 */
export const triTernaryConcatenation = (if1, return1, if2, return2, if3, return3, otherwhise) => {
  return if1 ? return1 : if2 ? return2 : if3 ? return3 : otherwhise
}

/**
 * @description Desglosa los rangos de descuento.
 * @param {string} discounts Rangos de descuento.
 * @returns {array|null}
 */
export const getDiscountsRanges = (discounts) => {
  return discounts
    ? discounts.split('/').reduce((t, v) => {
        if (v) {
          const [min, discount] = v.replace(/[\s-%]/g, '').split('~')
          t.push({
            min: parseInt(min, 10),
            discount: discount ? parseFloat(discount.replace(',', '.')) : 0,
          })
        }
        return t
      }, [])
    : null
}

/**
 * @description Retorna el rango de descuento activo dependiendo de la cantidad.
 * @param {array} discountRanges Rangos de descuento.
 * @param {Number} quantity Cantidad de unidades seleccionadas.
 * @returns {array}
 */
export const getDiscountRange = (discountRanges, quantity) => {
  const selectedRangeIndex = discountRanges.findIndex(
    (range, i) =>
      quantity >= range.min && (!discountRanges[i + 1] || quantity < discountRanges[i + 1].min)
  )
  const selectedRangeDiscount =
    selectedRangeIndex >= 0 ? discountRanges[selectedRangeIndex].discount : 0

  return [discountRanges, { index: selectedRangeIndex, value: selectedRangeDiscount }]
}
