import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components'

const CACHELOADERMESSAGES = {
  products: {
    init: 'Esperando productos...',
    downloading: 'Descargando productos...',
    saving: 'Guardando productos...',
    ok: 'Productos actualizados',
  },
  online: {
    init: 'Esperando promociones...',
    downloading: 'Descargando promociones...',
    saving: 'Guardando promociones...',
    ok: 'Promociones actualizadas',
  },
  offers: {
    init: 'Esperando ofertas...',
    downloading: 'Descargando ofertas...',
    saving: 'Guardando ofertas...',
    ok: 'Ofertas actualizadas',
  },
  transfers: {
    init: 'Esperando transfers...',
    downloading: 'Descargando transfers...',
    saving: 'Guardando transfers...',
    ok: 'Transfers actualizados',
  },
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const CacheLoaderContainer = styled.div`
  animation: ${fadeIn} 0.4s linear 0s 1;
  position: fixed;
  z-index: 9999;
  bottom: 25px;
  left: 25px;
  right: 25px;
  background: #202124;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.7rem;
  margin-top: 0.6rem;
  padding: 1.2em 1.6em 1.2em 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  .cache-loader__loader {
    flex: 0 1 auto;
    margin-right: 2rem;
  }
  .cache-loader__messages {
    flex: 1 1 0%;
    font-size: 0.95em;
    ul {
      list-style-position: inside;
      margin: 0.4rem 0 0 0;
      padding: 0;
    }
  }
  .cache-loader__messages__title {
    font-size: 1.1em;
    font-weight: bold;
  }
  @media (min-width: 376px) {
    left: auto;
    padding-right: 6em;
  }
`

const CacheLoader = ({
  productsStatus,
  offersStatus,
  transfersStatus,
  onlinePromotionsStatus,
  ...restProps
}) => {
  const [displayFlag, setDisplayFlag] = React.useState(false)

  React.useEffect(() => {
    if (productsStatus === 'downloading' || offersStatus === 'downloading') {
      setDisplayFlag(true)
    } else {
      const timeoutId = setTimeout(() => {
        setDisplayFlag(true)
      }, 3000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [productsStatus, offersStatus, transfersStatus])

  if (!displayFlag) return null

  return (
    <CacheLoaderContainer {...restProps}>
      <div className="cache-loader__loader">
        <Loader active inline />
      </div>
      <div className="cache-loader__messages">
        <div className="cache-loader__messages__title">Actualizando datos</div>
        <ul>
          <li>{CACHELOADERMESSAGES['products'][productsStatus]}</li>
          <li>{CACHELOADERMESSAGES['online'][onlinePromotionsStatus]}</li>
          <li>{CACHELOADERMESSAGES['offers'][offersStatus]}</li>
          <li>{CACHELOADERMESSAGES['transfers'][transfersStatus]}</li>
        </ul>
      </div>
    </CacheLoaderContainer>
  )
}

CacheLoader.propTypes = {
  productsStatus: PropTypes.string,
  offersStatus: PropTypes.string,
  transfersStatus: PropTypes.string,
}

export default CacheLoader
