import React from 'react'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'

import HeaderButton from './HeaderButton'

import { COLOR } from '../globals'

const PhonePopup = styled.div`
  & .title {
  }
  & .number {
    font-size: 1.2em;
    font-weight: bold;
    color: ${COLOR};
  }
`

/**
 * Component: HeaderPhone
 * Header pone icon that displays the company phone.
 */
const HeaderPhone = React.memo(({ ...restProps }) => {
  return (
    <Popup
      trigger={<HeaderButton icon="phone" href="tel:+34942333100" {...restProps} />}
      content={
        <PhonePopup>
          <div className="title">Teléfono</div>
          <div className="number">942 333 100</div>
        </PhonePopup>
      }
      // on={['hover', 'click']}
      on={['hover']}
    />
  )
})

export default HeaderPhone
