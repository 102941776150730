import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DebounceInput from 'react-debounce-input'
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

import { useClickOutside } from '../hooks/useClickOutside'

const SearchContainer = styled.div`
  position: relative;
  & .search__results {
    position: absolute;
    z-index: 3000;
    background: #fff;
    border: 1px solid #ddd;
    border-top: none;
    width: 100%;
    font-size: 0.9em;
  }
  & .search__results__result {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem 1rem;
    &:hover {
      cursor: pointer;
      background: #eee;
    }
  }
  & .search__results__result--focused {
    background: #eee;
  }
`

const Search = ({
  items,
  itemsFilter,
  itemRender,
  nResults,
  initialSearchValue,
  onResultSelection,
  icon,
  placeholder,
  ...restProps
}) => {
  const [search, setSearch] = useState(initialSearchValue)
  const [results, setResults] = useState([])
  const [focusedResultIndex, setFocusedResultIndex] = useState(null)
  const [selectedResult, setSelectedResult] = useState(false)

  const handleClickOutside = (e) => setSearch('')

  const { ref: resultsListRef } = useClickOutside(handleClickOutside)

  useEffect(() => {
    setSearch(initialSearchValue)
    if (!initialSearchValue) {
      setSelectedResult(false)
    }
  }, [initialSearchValue])

  useEffect(() => {
    if (!search) {
      setResults([])
      setFocusedResultIndex(null)
      onResultSelection('')
    } else {
      const filteredResults = itemsFilter(search, items).slice(0, nResults)
      setResults(filteredResults)
    }
    // eslint-disable-next-line
  }, [search, itemsFilter, nResults])

  const handleSearchChange = (e) => {
    setSelectedResult(false)
    setSearch(e.target.value)
  }

  const handleKeys = ({ keyCode }) => {
    // ESC.
    if (keyCode === 27) {
      setSearch('')
      setSelectedResult(false)
      return
    }

    const nCurrentResults = results.length
    if (nCurrentResults > 0) {
      // Cursor DOWN.
      if (keyCode === 40) {
        setFocusedResultIndex((prevState) =>
          prevState === null ? 0 : (prevState + 1) % nCurrentResults
        )
      }
      // Cursor UP.
      else if (keyCode === 38) {
        setFocusedResultIndex((prevState) =>
          prevState === null
            ? nCurrentResults - 1
            : (prevState + nCurrentResults - 1) % nCurrentResults
        )
      }
      // ENTER.
      else if (keyCode === 13 && focusedResultIndex !== null && focusedResultIndex >= 0) {
        const [resultKey, resultName] = itemRender(results[focusedResultIndex])
        setSelectedResult(true)
        setSearch(resultName)
        onResultSelection(resultKey)
      }
    }
  }

  const handleResultSelection = (selectionKey, selectionName) => {
    setSelectedResult(true)
    setSearch(selectionName)
    onResultSelection(selectionKey)
  }

  return (
    <SearchContainer>
      <DebounceInput
        autoComplete="off"
        fluid
        element={Input}
        minLength={1}
        debounceTimeout={200}
        // type="search"
        icon={icon}
        placeholder={placeholder}
        value={search}
        onChange={handleSearchChange}
        onKeyUp={handleKeys}
        {...restProps}
      />
      {!selectedResult && results.length > 0 && (
        <div className="search__results" ref={resultsListRef}>
          {results.map((result, i) => {
            const [resultKey, resultName] = itemRender(result)
            return (
              <div
                key={`${result.id}-${i}`}
                className={`search__results__result${
                  i === focusedResultIndex ? ' search__results__result--focused' : ''
                }`}
                onClick={() => handleResultSelection(resultKey, resultName)}
              >
                {resultName}
              </div>
            )
          })}
        </div>
      )}
    </SearchContainer>
  )
}

Search.propTypes = {
  items: PropTypes.array.isRequired,
  itemsFilter: PropTypes.func.isRequired,
  itemRender: PropTypes.func.isRequired,
  nResults: PropTypes.number,
  initialSearchValue: PropTypes.string,
  onResultSelection: PropTypes.func,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
}

Search.defaultProps = {
  nResults: 5,
  initialSearchValue: '',
  onResultSelection: () => null,
  icon: 'search',
  placeholder: 'Seleccionar producto...',
}

export default Search
